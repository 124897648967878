export const RESET_CONTAINER_CONFIG = "RESET_CONTAINER_CONFIG";


export function resetContainerConfig() {
  return { type: RESET_CONTAINER_CONFIG };
}

export const SET_CONTAINER_ID = "SET_CONTAINER_ID";
export const SET_CONTAINER_PICKING_ID = "SET_CONTAINER_PICKING_ID";
export const SET_CONTAINER_NAME = "SET_CONTAINER_NAME";
export const SET_CONTAINER_QTY = "SET_CONTAINER_PICKED";
export const SET_CONTAINER_SCRAP = "SET_CONTAINER_SCRAP";
export const SET_CONTAINER_NOTES = "SET_CONTAINER_NOTES";
export const SET_CONTAINER_SUPPLIER = "SET_CONTAINER_SUPPLIER";
export const SET_CONTAINER_BATCH = "SET_CONTAINER_BATCH";
export const SET_CONTAINER_ITEM = "SET_CONTAINER_ITEM";
export const SET_CONTAINER_ERROR = "SET_CONTAINER_ERROR";
export const SET_CONTAINER_ACTION = "SET_CONTAINER_ACTION";
export const SET_CONTAINER_ACTION_ORIGIN = "SET_CONTAINER_ACTION_ORIGIN";
export const SET_CONTAINER_SERIALNUMBER_CODE = "SET_CONTAINER_SERIALNUMBER_CODE";

export function setContainerId(value = null) {
  return { type: SET_CONTAINER_ID, value };
}

export function setContainerPickingId(value = null) {
  return { type: SET_CONTAINER_PICKING_ID, value };
}

export function setContainerName(value = null) {
  return { type: SET_CONTAINER_NAME, value };
}

export function setContainerQty(value = null) {
  return { type: SET_CONTAINER_QTY, value };
}

export function setContainerScrap(value = null) {
  return { type: SET_CONTAINER_SCRAP, value };
}

export function setContainerNotes(value = null) {
  return { type: SET_CONTAINER_NOTES, value };
}

export function setContainerSupplier(value = null) {
  return { type: SET_CONTAINER_SUPPLIER, value };
}

export function setContainerSerialnumberCode(value = null) {
  return { type: SET_CONTAINER_SERIALNUMBER_CODE, value };
}

export function setContainerItem(value = null) {
  return { type: SET_CONTAINER_ITEM, value };
}

export function setContainerBatch(value = null) {
  return { type: SET_CONTAINER_BATCH, value };
}

export function setContainerAction(value = null) {
  return { type: SET_CONTAINER_ACTION, value };
}

export function setContainerActionOrigin(value = null) {
  return { type: SET_CONTAINER_ACTION_ORIGIN, value };
}

export function setContainerError(value = null) {
  return { type: SET_CONTAINER_ERROR, value };
}
