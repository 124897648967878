import React from "react";
import styled from "styled-components";
import { serialnumbersRange } from "../libs/shared-functions";

const Badge = styled.span`
  border-radius: 1.4rem;
  padding: 0.4rem 1.2rem 0.4rem 0.9rem;
  display: inline-block;
  margin-right: 0.4rem;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
  color: #d9e0ec;
  background-color: #4a4a4a;
`;

function SerialnumbersBadge({ serialnumbers }) {
  if (!serialnumbers || serialnumbers.length === 0) {
    return <></>;
  }

  serialnumbers = serialnumbers.filter((serialnumber) => serialnumber?.code);

  return (
    <Badge>
      <i className="icon-star-empty" />
      {serialnumbersRange({ serialnumbers })}
    </Badge>
  );
}

export default SerialnumbersBadge;
