import PropTypes from "prop-types";
import React, { useState } from "react";
import styled from "styled-components";

import Avatar from "../../../../components/Avatar";
import VerifyPin from "../../../../components/VerifyPin";

const StyledWrapper = styled.div`
  .image {
    margin-bottom: 0.8rem;
  }

  .image,
  a {
    width: 12.8rem;
    height: 12.8rem;
  }
`;

function Operator({ operator, onClick }) {
  const [showVerify, setShowVerify] = useState(false);

  function handleClick() {
    if (operator.pin) {
      setShowVerify(true);
      return;
    }
    onClick(operator._id);
  }

  //const total = operator.jobs.length;
  return (
    <StyledWrapper className="column is-3 has-text-centered">
      <figure
        className="image"
        style={{ marginBottom: 8 }}
        data-cy="configuration-operators-operator"
        data-cy-id={operator._id}
      >
        <a onClick={handleClick}>
          <Avatar size="12.8rem" name={operator.name} src={operator.image} />
        </a>
      </figure>
      <h4 className="title is-4 w500 has-text-centered">{operator.name}</h4>
      <p className="subtitle is-5 fg-secondary has-text-centered">
        {/*<FormattedMessage
          id="app.shared.order_assigned"
          defaultMessage="{total, plural, =0 {No order assigned} one {{total, number} order assigned} other {{total, number} orders assigned}}"
          values={{ total }}
  />*/}
      </p>
      {showVerify && (
        <VerifyPin
          title={operator.name}
          verify={(pin) => operator.pin === pin}
          onSuccess={() => onClick(operator._id)}
          onCancel={() => setShowVerify(false)}
        />
      )}
    </StyledWrapper>
  );
}

Operator.propTypes = {
  onClick: PropTypes.func.isRequired,
  operator: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    pin: PropTypes.string,
    role: PropTypes.string,
    image: PropTypes.string,
  }).isRequired,
};
export default Operator;
