import en from "./english.json";
import it from "./italian.json";
import ru from "./russian.json";
import next from "./customers/it-next";
import config from "../config/config";

const customer = config.customer;
export default function () {
  if (customer === "it-next") {
    return {
      en: { ...en, ...next.en },
      it: { ...it, ...next.it },
      ru: { ...ru, ...next.ru },
    };
  }
  return {
    en,
    it,
    ru,
  };
}
