import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { ThemeProvider } from "styled-components";
import { useSelector } from "react-redux";

import Background from "../../components/Layout";
import themes from "../../libs/themes";

/**
 * An empty layout with centered text
 * @param children The content
 * @param theme
 * @constructor
 */
function CenteredLayout({ children, theme = themes.neutralGrey }) {
  const { isLoading } = useSelector(({ application }) => application);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
      </Helmet>
      <ThemeProvider theme={theme}>
        <Background
          headHasBorderBottom={false}
          head={null}
          isLoading={isLoading}
        >
          <div className="columns">
            <div className="column is-full has-text-centered">{children}</div>
          </div>
        </Background>
      </ThemeProvider>
    </>
  );
}

CenteredLayout.propTypes = {
  theme: PropTypes.shape({
    border: PropTypes.string.isRequired,
    bg: PropTypes.string.isRequired,
    fg: PropTypes.string.isRequired,
    buttons: PropTypes.shape({
      bg: PropTypes.string.isRequired,
      fg: PropTypes.string.isRequired,
    }).isRequired,
  }),
  children: PropTypes.element,
};

export default CenteredLayout;
