import moment from "moment";
import "moment/locale/en-gb";
import "moment/locale/ru";
import "moment/locale/it";

export default function load(language) {
  switch (language) {
    case "en": // skip, it is default language
      break;
    case "ru":
    case "it":
      moment.locale(language);
      break;
    default:
      console.error("Language not recognized, check config/libs/numbers.js");
  }
}
