import { useMutation } from "@apollo/client";
import classNames from "classnames";
import { loader } from "graphql.macro";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import { MediumButton } from "../../../../components/Button";
import TextareaField from "../../../../components/Fields/TextareaField";
import Header from "../../../../components/Header";
import Background from "../../../../components/Layout";
import { SmallTag } from "../../../../components/Tag";
import Title from "../../../../components/Title";
import themes, { buttons } from "../../../../libs/themes";

const UPSERT_SESSION = loader("./Notes.mutation.graphql");

function Notes() {
  const history = useHistory();
  const { phaseId, sessionId } = useParams();
  const theme = themes.default;
  const [mutate] = useMutation(UPSERT_SESSION);

  const { delivery, config } = useSelector(({ delivery, config }) => ({
    delivery,
    config,
  }));
  const { hmiId, stationId } = config;

  const [notes, setNotes] = useState(delivery.session.notes);

  function onBack() {
    if (sessionId) {
      history.push(`/worker/manual-session/time/${phaseId}/${sessionId}`);
    } else {
      history.push(`/worker/manual-session/time/${phaseId}`);
    }
  }

  async function onSubmit() {
    await mutate({
      variables: {
        sessionId,
        phaseId,
        hmiId,
        stationId,
        start: delivery.session.start,
        end: delivery.session.end,
        status: delivery.status,
        notes,
      },
    });
    history.push("/worker/timesheet");
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <Background head={<Header hasSessionInfo={false} hasUserInfo={true} />}>
          <>
            <div className="columns">
              <div className="column">
                <SmallTag isUpperCase isLight theme={buttons.neutralGrey}>
                  <Choose>
                    <When condition={delivery.status === "INTERRUPT"}>
                      <FormattedMessage
                        id="app.shared.delivery.partial"
                        defaultMessage="Partial delivery"
                      />
                    </When>
                    <When condition={delivery.status === "DONE"}>
                      <FormattedMessage
                        id="app.shared.delivery.final"
                        defaultMessage="Final delivery"
                      />
                    </When>
                    <Otherwise>
                      <FormattedMessage
                        id="app.shared.delivery"
                        defaultMessage="Delivery"
                      />
                    </Otherwise>
                  </Choose>
                </SmallTag>
                <Title.H1>
                  <FormattedMessage
                    id="app.pages.application.notes.title"
                    defaultMessage="How many pieces?"
                  />
                </Title.H1>
              </div>
              <div className={classNames("column", "is-3")}>
                <div className="columns">
                  <div className="column">
                    <MediumButton
                      isFullWidth
                      theme={buttons.default}
                      onClick={onBack}
                      data-cy="application-button-back"
                    >
                      <FormattedMessage
                        id="app.shared.back"
                        defaultMessage="Back"
                      />
                    </MediumButton>
                  </div>
                  <div className="column">
                    <MediumButton
                      isFullWidth
                      theme={buttons.productionBlue}
                      onClick={onSubmit}
                      data-cy="application-button-ok"
                    >
                      <FormattedMessage
                        id="app.shared.ok"
                        defaultMessage="Ok"
                      />
                    </MediumButton>
                  </div>
                </div>
              </div>
            </div>
            <>
              <TextareaField
                key="notes"
                autofocus={true}
                placeholder="Notes"
                defaultValue={notes}
                onChange={(e) => setNotes(e.target.value)}
              />
            </>
          </>
        </Background>
      </ThemeProvider>
    </>
  );
}

Notes.propTypes = {};

export default Notes;
