import { SET_LOADING } from "../actions";

const INITIAL_STATE = {
  isLoading: false,
};
/**
 * Application reducer
 * @param state
 * @param action
 * @returns {*}
 */
const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_LOADING:
      return { ...state, isLoading: action.isLoading };
    default:
      return state;
  }
};

export default reducer;
