import React from "react";
import { useQuery } from "@apollo/client";

import Title from "../Title";
import Subtitle from "../Subtitle";
import Loader from "../Loader";
import Avatar from "../Avatar";

import { loader } from "graphql.macro";
const HMI_INFO = loader("./HmiInfo.query.graphql");

/**
 * @description Shows some informations about hmi
 *
 * @returns {*}
 * @constructor
 */
function HmiInfo() {
  const { loading, error, data } = useQuery(HMI_INFO, {
    variables: { hmiId: window.HMI_ID },
  });
  if (loading && !data) {
    return (
      <div className="level">
        <div className="level-left">
          <div className="level-item">
            <Loader message={null} />
          </div>
        </div>
      </div>
    );
  }
  if (error) {
    return `Error! ${error.message}`;
  }
  const { hmi } = data;
  return (
    <div className="level">
      <div className="level-left">
        <div className="level-item">
          <Avatar size="9.6rem" name={hmi.name} src={hmi.image} />
        </div>
        <div className="level-item">
          <div>
            <Title.H2 isSpaced>{hmi.name}</Title.H2>
            <Subtitle.S4>{hmi.description}</Subtitle.S4>
          </div>
        </div>
      </div>
    </div>
  );
}

HmiInfo.propTypes = {};
export default HmiInfo;
