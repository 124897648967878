import Joi from "joi-browser";
import env from "./env";

const envVarsSchema = Joi.object({
  REACT_APP_API: Joi.string().uri().required(),
  REACT_APP_CONFIG_API: Joi.string().uri().required(),
  REACT_APP_GRAPHQL_API: Joi.string().uri().required(),
  REACT_APP_GRAPHQL_WS: Joi.string().uri().required(),
  REACT_APP_FORCE_LANG: Joi.string().allow(""),
  REACT_APP_SHOW_SECONDS: Joi.boolean().default(false),
  REACT_APP_EXPERIMENTAL: Joi.boolean().default(false),
  REACT_APP_SENTRY_DSN_HMI: Joi.string().required(),
  REACT_APP_VERSION: Joi.string().required(),
  REACT_APP_BUILD: Joi.string().required(),
  REACT_APP_CACHE_RELOAD_TIMEOUT: Joi.number().default(1000 * 60 * 5), // 5 minutes
  NODE_ENV: Joi.string().required(),
})
  .unknown()
  .required();

const { error, value: envVars } = envVarsSchema.validate(env);
if (error) {
  throw new Error(`Config validation error: ${error.message}`);
}

const config = {
  configApi: envVars.REACT_APP_CONFIG_API,
  api: envVars.REACT_APP_API,
  customer: envVars.REACT_APP_CUSTOMER,
  graphql: {
    api: envVars.REACT_APP_GRAPHQL_API,
    ws: envVars.REACT_APP_GRAPHQL_WS,
  },
  forceLang: envVars.REACT_APP_FORCE_LANG,
  showSeconds: envVars.REACT_APP_SHOW_SECONDS,
  experimental: envVars.REACT_APP_EXPERIMENTAL,
  sentry: envVars.REACT_APP_SENTRY_DSN_HMI,
  version: envVars.REACT_APP_VERSION,
  build: envVars.REACT_APP_BUILD,
  cacheReloadTimeout: envVars.REACT_APP_CACHE_RELOAD_TIMEOUT,
  env: envVars.NODE_ENV,
};

console.log("Config", config);

export default config;
