import { useQuery } from "@apollo/client";
import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";

import Docs from "../../../../components/Docs";
import LoadingIndicator from "../../../../components/LoadingIndicator";
import Notes from "../../../../components/Notes";
import StepHeader from "../../../../components/StepHeader";
import Tag, { SmallTag } from "../../../../components/Tag";
import Timer from "../../../../components/Timer";
import Title from "../../../../components/Title";
import themes from "../../../../libs/themes";

import { loader } from "graphql.macro";
import styled from "styled-components";
import Hoverable from "../hoverable";
const SETUP = loader("./SetupBody.query.graphql");

/**
 * Setup page
 *
 * @param stepName
 * @param phase
 * @constructor
 */

const Column = styled.div`
  min-width: 0;
`;

function SetupBody({ stepName = "SETUP" }) {
  const { phaseId, sessionId } = useParams();
  const variables = { phaseId, sessionId };
  const { data, loading, error } = useQuery(SETUP, { variables });
  if (loading && !data) {
    return <LoadingIndicator />;
  }
  if (error) {
    return `Error! ${error.message}`;
  }
  const { phase, session } = data;
  return (
    <>
      <div className="columns">
        <Column className="column">
          <SmallTag isUpperCase isLight theme={themes.setupYellow.buttons}>
            {stepName || (
              <FormattedMessage id="app.shared.setup" defaultMessage="Setup" />
            )}
          </SmallTag>
          <Hoverable text={phase.sort + " :: " + phase.name}>
            <Title.H1 isEllipsed>
              {phase.sort} :: {phase.name}
            </Title.H1>
          </Hoverable>
          <StepHeader />
        </Column>
        <div className="column is-narrow">
          <Tag theme={themes.setupYellow.buttons}>
            <i className="icon-giphy-time" />
            <Timer time={session.timers.SETUP || 0} active />
            <small style={{ color: "rgba(255,255,255,.85)" }}>
              {" / "}
              <Timer time={session.timers.TOTAL || 0} active />
            </small>
          </Tag>
        </div>
      </div>
      <div className="columns">
        <div className="column has-text-left">
          <Docs filterFn={(doc) => doc.type === "SETUP"} />
        </div>
        <div className="column has-text-left">
          <Notes />
        </div>
      </div>
    </>
  );
}

SetupBody.propTypes = {
  stepName: PropTypes.string,
};

export default SetupBody;
