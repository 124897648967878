import { useMutation, useQuery } from "@apollo/client";
import classNames from "classnames";
import { loader } from "graphql.macro";
import React, { useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import { setScrap } from "../../../actions";
import { MediumButton } from "../../../components/Button";
import NumberField from "../../../components/Fields/NumberField";
import Header from "../../../components/Header";
import Background from "../../../components/Layout/Background";
import LoadingIndicator from "../../../components/LoadingIndicator";
import StepHeader from "../../../components/StepHeader";
import { SmallTag } from "../../../components/Tag";
import Title from "../../../components/Title";
import CenteredLayout from "../../../layouts/CenteredLayout/CenteredLayout.layout";
import themes, { buttons } from "../../../libs/themes";
import GenericError from "../../errors/GenericError";

const SCRAP_QUERY = loader("./Scrap.query.graphql");
const SET_SCRAP = loader("./Scrap.mutation.graphql");

function Scrap({ status }) {
  const [isError] = useState(false);
  const { phaseId, sessionId } = useParams();
  const [currentSession] = useState(sessionId);

  const delivery = useSelector(({ delivery }) => delivery);
  const { hmiConfiguration } = useSelector(({ config }) => config);

  const dispatch = useDispatch();
  const history = useHistory();

  const variables = { sessionId: currentSession };

  const [mutate] = useMutation(SET_SCRAP);

  const { loading, error, data } = useQuery(SCRAP_QUERY, { variables });

  const theme = themes.default;
  if (loading && !data) {
    return (
      <CenteredLayout theme={theme}>
        <LoadingIndicator />
      </CenteredLayout>
    );
  }
  if (error) {
    return <GenericError error={error.message} />;
  }

  const { session } = data;
  const { phase } = session;

  function onBack() {
    dispatch(setScrap());
    history.goBack();
  }

  async function onSubmit() {
    const scrap = delivery.session.scrap ?? session.scrap ?? 0;
    const produced = delivery.session.produced ?? session.produced ?? 0;
    const { SUBTRACT_SCRAP } = phase.configuration;

    if (isNaN(scrap)) {
      this.showError("app.shared.nan");
      return;
    }
    if (scrap > produced && SUBTRACT_SCRAP) {
      this.showError(
        "app.pages.application.scrap.error.scrap_greater_than_produced"
      );
      return;
    }
    await saveAndNext(scrap);
  }

  async function saveAndNext(value) {
    await mutate({
      variables: {
        sessionId: currentSession,
        scrap: value,
      },
    });

    if (hmiConfiguration.SKIP_PICKED || session.picking.length == 0) {
      return history.push(`/application/notes/${phaseId}/${sessionId}`);
    }
    return history.push(`/application/picked/${phaseId}/${sessionId}`);
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <Background head={<Header hasPhaseInfo />}>
          <>
            <div className="columns">
              <div className="column">
                <SmallTag isUpperCase isLight theme={buttons.neutralGrey}>
                  <Choose>
                    <When condition={status === "INTERRUPT"}>
                      <FormattedMessage
                        id="app.shared.delivery.partial"
                        defaultMessage="Partial delivery"
                      />
                    </When>
                    <When condition={status === "DONE"}>
                      <FormattedMessage
                        id="app.shared.delivery.final"
                        defaultMessage="Final delivery"
                      />
                    </When>
                    <Otherwise>
                      <FormattedMessage
                        id="app.shared.delivery"
                        defaultMessage="Delivery"
                      />
                    </Otherwise>
                  </Choose>
                </SmallTag>
                <Title.H1>
                  <FormattedMessage
                    id="app.pages.application.scrap.title"
                    defaultMessage="Insert scrap"
                  />
                </Title.H1>
                <StepHeader type="INPUT" />
              </div>
              <div className="column is-3">
                <div className="columns">
                  <div className="column">
                    <MediumButton
                      isFullWidth
                      theme={buttons.default}
                      onClick={onBack}
                      data-cy="application-button-back"
                    >
                      <FormattedMessage
                        id="app.shared.back"
                        defaultMessage="Back"
                      />
                    </MediumButton>
                  </div>
                  <div className="column">
                    <MediumButton
                      isFullWidth
                      theme={buttons.productionBlue}
                      onClick={onSubmit}
                      data-cy="application-button-ok"
                    >
                      <FormattedMessage
                        id="app.shared.ok"
                        defaultMessage="Ok"
                      />
                    </MediumButton>
                  </div>
                </div>
              </div>
            </div>
            <div className="columns">
              <div
                className={classNames(
                  "column has-text-centered",
                  isError && "animated shake duration400"
                )}
              >
                <NumberField
                  cy="application-scrap-input"
                  autofocus={true}
                  key="scrap"
                  placeholder="0"
                  defaultValue={delivery.session.scrap || session.scrap || 0}
                  onChange={(value) => dispatch(setScrap(value))}
                />
              </div>
            </div>
          </>
        </Background>
      </ThemeProvider>
    </>
  );
}

Scrap.propTypes = {};

export default injectIntl(Scrap);
