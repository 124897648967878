import onError from "../../../libs/error-logger";
import config from "../../../config/config";

function handleErrors(response) {
  if (!response.ok) {
    return Promise.reject({ message: response.statusText });
  }
  return response;
}
/**
 * Add doc to phase
 * @param phaseId
 * @param doc
 * @param intl
 * @param mutate
 */
const onAdd = async (phaseId, doc, refetch, intl) => {
  const url = `${config.api}/api/v1/it-sssn/upload/folder/${phaseId}`;
  await fetch(url, { method: "GET" })
    .then(handleErrors)
    .then((res) => {
      console.log(res);
      refetch();
    })
    .catch(onError);
};
export default onAdd;
