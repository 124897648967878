import { useQuery } from "@apollo/client";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import { setProgram } from "../../../../actions";
import Button from "../../../../components/Button";
import Clock from "../../../../components/Clock";
import Header from "../../../../components/Header";
import Background from "../../../../components/Layout";
import Subtitle from "../../../../components/Subtitle";
import Title from "../../../../components/Title";
import BackImage from "../../../../images/arrow-back-gray-icn.svg";
import Programs from "./components/Programs";

import { loader } from "graphql.macro";
import LoadingIndicator from "../../../../components/LoadingIndicator";
import CenteredLayout from "../../../../layouts/CenteredLayout/CenteredLayout.layout";
import themes from "../../../../libs/themes";
const PROGRAMS = loader("./AdvancedProgram.query.graphql");

function AdvancedProgram() {
  const theme = themes.default;
  const history = useHistory();
  const dispatch = useDispatch();
  const { jobId, phaseId } = useParams();
  const { machineId, programId } = useSelector(({ config }) => config);

  const { data, loading, error } = useQuery(PROGRAMS, {
    variables: { machineId },
    skip: machineId == null,
  });

  if (loading && !data) {
    return (
      <CenteredLayout>
        <LoadingIndicator />
      </CenteredLayout>
    );
  }
  if (error) {
    return `Error! ${error.message}`;
  }

  const { programs } = data || {};

  function onClick(id) {
    if (programId === id) {
      return dispatch(setProgram());
    }
    dispatch(setProgram(id));
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <Background
          head={
            <Header hasSessionInfo={false} hasClock={false}>
              <div className="column has-text-centered">
                <Clock />
              </div>
            </Header>
          }
          foot={
            <div className="columns">
              <div className="column is-1">
                <Button
                  isFullWidth
                  onClick={() =>
                    history.push(
                      `/configuration/advanced/menu/${jobId}/${phaseId}`
                    )
                  }
                  data-cy="application-button-back"
                >
                  <img src={BackImage} alt="" />
                </Button>
              </div>
            </div>
          }
        >
          {machineId && (
            <>
              <div className="columns">
                <div className="column has-text-centered">
                  <Title.H1>
                    <FormattedMessage
                      id="app.pages.configuration.advanced.programs.title"
                      defaultMessage="Choose program"
                    />
                  </Title.H1>
                  <Subtitle.S3 theme={themes.secondary}>
                    <FormattedMessage
                      id="app.pages.configuration.advanced.programs.subtitle"
                      defaultMessage="Tell me on which program you will work"
                    />
                  </Subtitle.S3>

                  <Programs onClick={onClick} programs={programs} />
                  {programs.length === 0 && (
                    <Title.H2>
                      <FormattedMessage
                        id="app.pages.configuration.advanced.programs.empty"
                        defaultMessage="Sorry, no programs available"
                      />
                    </Title.H2>
                  )}
                </div>
              </div>
            </>
          )}
          {!machineId && (
            <h1 className="title is-2">
              <FormattedMessage
                id="app.pages.configuration.advanced.programs.no_machine"
                defaultMessage="No machine associated"
              />
            </h1>
          )}
        </Background>
      </ThemeProvider>
    </>
  );
}

export default AdvancedProgram;
