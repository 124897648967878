import { useSubscription } from "@apollo/client";
import { useHistory, useParams } from "react-router-dom";

import { loader } from "graphql.macro";
const PHASE_SESSION_SUBSCRIPTION = loader("./CheckStatus.subscription.graphql");

function CheckStatus({ children, onStatusChange }) {
  const history = useHistory();
  const { sessionId, phaseId, stepId } = useParams();
  const variables = { sessionId };

  // Listen for status if INTERRUPT close the session
  useSubscription(PHASE_SESSION_SUBSCRIPTION, {
    variables,
    onData: ({ data: subscriptionData }) => {
      if (subscriptionData?.data?.phaseSessionUpdated?.status == "INTERRUPT") {
        return history.replace(`/worker/dashboard`);
      }
      if (subscriptionData?.data?.phaseSessionUpdated?.status == "PAUSE") {
        return history.push(
          `/application/pause/${phaseId}/${sessionId}/${stepId}`
        );
      }
      if (onStatusChange) {
        onStatusChange(subscriptionData?.data?.phaseSessionUpdated?.status);
      }
    },
  });
  return children;
}

export default CheckStatus;
