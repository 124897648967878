import classNames from "classnames";
import React, { useRef, useState } from "react";
import DatePicker from "react-datepicker";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import { setTime } from "../../../../actions";
import Button, { MediumButton } from "../../../../components/Button";
import Header from "../../../../components/Header";
import Background from "../../../../components/Layout";
import { SmallTag } from "../../../../components/Tag";
import Title from "../../../../components/Title";
import themes, { buttons } from "../../../../libs/themes";
import toast from "../../../../libs/toast";
import minutesToHHMM from "../../../../utils/minutes-to-hhmm";

function Time() {
  const intl = useIntl();
  const history = useHistory();
  const { phaseId, sessionId } = useParams();

  const startRef = useRef(null);
  const endRef = useRef(null);

  const theme = themes.default;
  const dispatch = useDispatch();

  const { delivery } = useSelector(({ delivery, config }) => ({
    delivery,
    config,
  }));

  const [start, setStart] = useState(new Date(delivery.session.start));
  const [end, setEnd] = useState(new Date(delivery.session.end));

  function setStartDay(date) {
    // Change date time to start.hours and start.minutes
    var newdate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      start.getHours(),
      start.getMinutes()
    );
    setStart(newdate);
  }

  function setNewTime(v, date, set) {
    var parts = v.split(":");

    set(
      new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        parts[0],
        parts[1]
      )
    );
  }

  function add15(val, set) {
    var diff = 15;
    var newdate = new Date(val.getTime() + diff * 60000);
    set(newdate);
  }

  function subtract15(val, set) {
    var diff = -15;
    var newdate = new Date(val.getTime() + diff * 60000);
    set(newdate);
  }

  function operationTotal() {
    var difference = end - start > 0 ? end - start : 0;
    return difference / 60000;
  }

  function onBack() {
    if (sessionId) {
      history.push("/worker/timesheet");
    } else {
      history.push("/configuration/add-operations");
    }
  }

  async function onSubmit() {
    var difference = end - start;
    if (isNaN(difference)) {
      toast({
        title: intl.formatMessage({ id: "app.shared.nan" }),
        icon: "error",
      });
      return;
    }
    if (Number(difference) < 0) {
      toast({
        title: intl.formatMessage({ id: "app.shared.gt_zero" }),
        icon: "error",
      });
      return;
    }
    dispatch(
      setTime({
        start: start.toISOString(),
        end: end.toISOString(),
      })
    );
    if (sessionId) {
      history.push(`/worker/manual-session/notes/${phaseId}/${sessionId}`);
    } else {
      history.push(`/worker/manual-session/notes/${phaseId}`);
    }
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <Background head={<Header hasSessionInfo={false} hasUserInfo={true} />}>
          <>
            <div className="columns">
              <div className="column">
                <SmallTag isUpperCase isLight theme={buttons.neutralGrey}>
                  <Choose>
                    <When condition={delivery.status === "INTERRUPT"}>
                      <FormattedMessage
                        id="app.shared.delivery.partial"
                        defaultMessage="Partial delivery"
                      />
                    </When>
                    <When condition={delivery.status === "DONE"}>
                      <FormattedMessage
                        id="app.shared.delivery.final"
                        defaultMessage="Final delivery"
                      />
                    </When>
                    <Otherwise>
                      <FormattedMessage
                        id="app.shared.delivery"
                        defaultMessage="Delivery"
                      />
                    </Otherwise>
                  </Choose>
                </SmallTag>
                <Title.H1>
                  <FormattedMessage
                    id="app.pages.application.times.title"
                    defaultMessage="Quando?"
                  />
                </Title.H1>
                <p className="subtitle is-6 fg-secondary">
                  Inserisci l'intervallo di tempo
                </p>
              </div>
              <div className={classNames("column", "is-3")}>
                <div className="columns">
                  <div className="column">
                    <MediumButton
                      isFullWidth
                      theme={buttons.default}
                      onClick={onBack}
                      data-cy="application-button-back"
                    >
                      <FormattedMessage
                        id="app.shared.back"
                        defaultMessage="Back"
                      />
                    </MediumButton>
                  </div>
                  <div className="column">
                    <MediumButton
                      isFullWidth
                      theme={buttons.productionBlue}
                      onClick={onSubmit}
                      data-cy="application-button-ok"
                    >
                      <FormattedMessage
                        id="app.shared.ok"
                        defaultMessage="Ok"
                      />
                    </MediumButton>
                  </div>
                </div>
              </div>
            </div>
            <>
              <div className="columns">
                <div className="column">
                  <span className="title is-4 fg-secondary">
                    <FormattedMessage
                      id="app.pages.configuration.operations.started"
                      defaultMessage="Start"
                    />
                  </span>
                  <div className="columns is-vcentered ">
                    <div className="column has-10-padding-right">
                      <input
                        type="time"
                        className="input is-1"
                        style={{ fontSize: "3rem", width: "95%" }}
                        ref={startRef}
                        // onFocus={() => setCurrentRef(startRef)}
                        onChange={(v) => {
                          setNewTime(v.target.value, start, setStart);
                        }}
                        value={`${start
                          .getHours()
                          .toString()
                          .padStart(2, "0")}:${start
                          .getMinutes()
                          .toString()
                          .padStart(2, "0")}`}
                      />
                    </div>

                    <div className="columns is-vcentered">
                      <div
                        className="column is-narrow"
                        style={{ textAlign: "center" }}
                      >
                        <Button
                          onClick={() => {
                            subtract15(start, setStart);
                          }}
                          className="button"
                          theme={themes.secondary.buttons}
                        >
                          <FormattedMessage
                            id="app.pages.configuration.operations.add15"
                            defaultMessage="-15"
                          />
                        </Button>
                      </div>
                      <div
                        className="column is-narrow"
                        style={{ textAlign: "center" }}
                      >
                        <Button
                          onClick={() => {
                            add15(start, setStart);
                          }}
                          className="button"
                          theme={themes.secondary.buttons}
                        >
                          <FormattedMessage
                            id="app.pages.configuration.operations.add15"
                            defaultMessage="+15"
                          />
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div
                    className="column is-paddingless has-30-margin-top"
                    style={{ textAlign: "left" }}
                  >
                    <span className="title is-4 fg-secondary">
                      <FormattedMessage
                        id="app.pages.configuration.operations.start.day"
                        defaultMessage="Start date"
                      />
                    </span>
                    <DatePicker
                      selected={start}
                      onChange={(date) => setStartDay(date)}
                      maxDate={new Date()}
                    />
                  </div>
                </div>
                <div className="column is-narrow"></div>
                <div className="column">
                  <span className="title is-4 fg-secondary">
                    <FormattedMessage
                      id="app.pages.configuration.operations.ended"
                      defaultMessage="End"
                    />
                  </span>
                  <div className="columns is-vcentered">
                    <div className="column">
                      <input
                        type="time"
                        className="input is-1"
                        style={{ fontSize: "3rem", width: "95%" }}
                        ref={endRef}
                        // onFocus={() => setCurrentRef(endRef)}
                        onChange={(v) => {
                          setNewTime(v.target.value, end, setEnd);
                        }}
                        value={`${end
                          .getHours()
                          .toString()
                          .padStart(2, "0")}:${end
                          .getMinutes()
                          .toString()
                          .padStart(2, "0")}`}
                      />
                    </div>

                    <div className="columns is-vcentered">
                      <div
                        className="column is-narrow"
                        style={{ textAlign: "center" }}
                      >
                        <Button
                          onClick={() => {
                            subtract15(end, setEnd);
                          }}
                          className="button"
                          theme={themes.secondary.buttons}
                        >
                          <FormattedMessage
                            id="app.pages.configuration.operations.add15"
                            defaultMessage="-15"
                          />
                        </Button>
                      </div>
                      <div
                        className="column is-narrow"
                        style={{ textAlign: "center" }}
                      >
                        <Button
                          onClick={() => {
                            add15(end, setEnd);
                          }}
                          className="button"
                          theme={themes.secondary.buttons}
                        >
                          <FormattedMessage
                            id="app.pages.configuration.operations.add15"
                            defaultMessage="+15"
                          />
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div
                    className="column is-paddingless has-30-margin-top"
                    style={{ textAlign: "left" }}
                  >
                    <span className="title is-4 fg-secondary">
                      <FormattedMessage
                        id="app.pages.configuration.operations.start.day"
                        defaultMessage="End date"
                      />
                    </span>
                    <DatePicker
                      selected={end}
                      onChange={(date) => setStartDay(date)}
                      maxDate={new Date()}
                    />
                  </div>
                  <div className="column is-paddingless has-30-margin-top">
                    <span className="title is-4 fg-secondary">
                      <FormattedMessage
                        id="app.pages.configuration.operations.start.day"
                        defaultMessage="Totale ore"
                      />
                    </span>
                    <input
                      type="text"
                      className="input is-1"
                      style={{ fontSize: "3rem", width: "100%" }}
                      value={minutesToHHMM(operationTotal())}
                      disabled
                    />
                  </div>
                </div>
              </div>
            </>

            {/*<OnScreenKeyboard inputNode={inputNode} layouts={['numeric']} />*/}
          </>
        </Background>
      </ThemeProvider>
    </>
  );
}

Time.propTypes = {};

export default Time;
