import { useQuery } from "@apollo/client";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import Button from "../../../components/Button";
import Docs from "../../../components/Docs";
import Header from "../../../components/Header";
import Background from "../../../components/Layout";
import Notes from "../../../components/Notes";
import SetStatus from "../../../components/SetStatus";
import StepHeader from "../../../components/StepHeader";
import Tag, { SmallTag } from "../../../components/Tag";
import Timer from "../../../components/Timer";
import Title from "../../../components/Title";
import themes from "../../../libs/themes";

import { loader } from "graphql.macro";
import CheckStatus from "../../../components/CheckStatus";
import LoadingIndicator from "../../../components/LoadingIndicator";
import CenteredLayout from "../../../layouts/CenteredLayout/CenteredLayout.layout";
const PAUSE = loader("./Pause.query.graphql");

function Pause() {
  const { phaseId, sessionId, stepId } = useParams();
  const history = useHistory();
  const variables = { phaseId, sessionId };
  const { data, loading, error } = useQuery(PAUSE, { variables });
  const theme = themes.pauseAzure;
  if (loading && !data) {
    return (
      <CenteredLayout theme={theme}>
        <LoadingIndicator />
      </CenteredLayout>
    );
  }
  if (error) {
    return `Error! ${error.message}`;
  }
  const { phase, session } = data;

  function resume() {
    if (phase.configuration.HMI_MODE === "FREE") {
      return history.goBack();
    }
    if (phase.configuration.HMI_MODE === "WORKFLOW") {
      return history.push(
        `/application/step/${phaseId}/${sessionId}/${stepId}`
      );
    }
  }

  return (
    <>
      <CheckStatus onStatusChange={(status) => resume()}>
        <SetStatus status="PAUSE">
          <ThemeProvider theme={theme}>
            <Background
              head={<Header hasPhaseInfo />}
              foot={
                <div className="columns">
                  <div className="column is-4 is-offset-4">
                    <Button
                      isFullWidth
                      theme={themes.primary.buttons}
                      onClick={resume}
                      data-cy="application-button-back"
                    >
                      <FormattedMessage
                        id="app.shared.resume"
                        defaultMessage="resume"
                      />
                    </Button>
                  </div>
                </div>
              }
            >
              <>
                <div className="columns">
                  <div className="column">
                    <SmallTag
                      isUpperCase
                      isLight
                      theme={themes.pauseAzure.buttons}
                    >
                      <FormattedMessage
                        id="app.shared.pause"
                        defaultMessage="Pause"
                      />
                    </SmallTag>
                    <Title.H1>
                      {phase.sort} :: {phase.name}
                    </Title.H1>
                    <StepHeader />
                  </div>
                  <div className="column is-narrow">
                    <Tag theme={themes.pauseAzure.buttons}>
                      <i className="icon-giphy-time" />
                      <Timer time={session.timers.PAUSE || 0} active />
                      <small style={{ color: "rgba(255,255,255,.85)" }}>
                        {" / "}
                        <Timer time={session.timers.TOTAL || 0} active />
                      </small>
                    </Tag>
                  </div>
                </div>
                <div className="columns">
                  <div className="column is-half has-text-left">
                    <Docs
                      filterFn={(doc) =>
                        ["BLUEPRINT", "PRODUCTION"].includes(doc.type)
                      }
                    />
                  </div>
                  <div className="column is-half has-text-left">
                    <Notes />
                  </div>
                </div>
              </>
            </Background>
          </ThemeProvider>
        </SetStatus>
      </CheckStatus>
    </>
  );
}
Pause.propTypes = {};

export default Pause;
