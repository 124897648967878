import React from "react";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import NumberFormatter from "../../libs/format-number";

import Avatar from "../Avatar";
import Loader from "../Loader";

import { loader } from "graphql.macro";
const SESSION = loader("./SessionInfo.query.graphql");

/**
 * Returns User Informations
 * @constructor
 */
function SessionInfo() {
  const { sessionId } = useParams();
  const { loading, error, data } = useQuery(SESSION, {
    variables: { sessionId },
  });

  if (loading && !data) {
    return (
      <article className="media">
        <div className="media-content" style={{ marginTop: "1rem" }}>
          <div className="content">
            <Loader message={null} />
          </div>
        </div>
      </article>
    );
  }
  if (error) {
    return `Error! ${error.message}`;
  }
  const { session } = data;

  const { worker, _team, machine, hmi } = session;
  return (
    <>
      <div className="columns">
        <div className="column is-narrow">
          <figure className="image is-48x48">
            <Avatar size="4.8rem" name={worker.name} src={worker.image} />
          </figure>
        </div>
        <div className="column">
          <h4 className="title is-4 w700">
            {worker.name}{" "}
            {machine && <small className="fg-secondary">{machine.name}</small>}
          </h4>
          <p className="subtitle is-6">
            {_team.length > 0 && (
              <>
                <i className="icon-st-users-icn" />{" "}
                <NumberFormatter value={_team.length} />
                <span className="fg-secondary">{" @ "}</span>
              </>
            )}
            <span className="fg-secondary">{hmi.name}</span>
          </p>
        </div>
      </div>
    </>
  );
}

SessionInfo.propTypes = {};

export default SessionInfo;
