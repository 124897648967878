import { useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import Header from "../../../../components/Header";
import Background from "../../../../components/Layout/Background";
import onError from "../../../../libs/error-logger";
import themes from "../../../../libs/themes";
import toast from "../../../../libs/toast";

import { loader } from "graphql.macro";
import { setLoading } from "../../../../actions";
import LoadingIndicator from "../../../../components/LoadingIndicator";
import CenteredLayout from "../../../../layouts/CenteredLayout/CenteredLayout.layout";
import GenericError from "../../../errors/GenericError";

import SelectField from "../../../../components/Fields/SelectField";
import TextareaField from "../../../../components/Fields/TextareaField";
import TextField from "../../../../components/Fields/TextField";
import HmiField from "../../../../components/HmiField/HmiField";

const CONTAINER_BATCH = loader("./ContainerBatch.query.graphql");
const CONTAINER_BATCH_MUTATION = loader("./ContainerBatch.mutation.graphql");
const BATCH_UPSERT = loader("../../_shared/UpsertBatch.mutation.graphql");

function ContainerBatch() {
  const intl = useIntl();
  const inputEl = useRef(null);
  const dispatch = useDispatch();
  const [batch, setBatch] = useState();
  const [supplier, setSupplier] = useState();
  const [notes, setNotes] = useState();
  const { containerId } = useParams();
  const [step, setStep] = useState(0);
  const [stepIndex, setStepIndex] = useState(0);
  const history = useHistory();
  const { hmiConfiguration } = useSelector(({ config }) => config);
  useEffect(() => {
    inputEl && inputEl.current && inputEl.current.select();
  }, [inputEl]);

  const variables = { containerId };

  const { loading, error, data } = useQuery(CONTAINER_BATCH, { variables });
  const [mutateContainerBatch] = useMutation(CONTAINER_BATCH_MUTATION, {
    variables,
  });
  const [mutateUpsertBatch] = useMutation(BATCH_UPSERT);

  if (loading && !data) {
    return (
      <CenteredLayout>
        <LoadingIndicator />
      </CenteredLayout>
    );
  }
  if (error) {
    return <GenericError error={error.message} />;
  }

  const { container, suppliers } = data;

  async function onSubmit() {
    dispatch(setLoading(true));
    try {
      const batchResult = await mutateUpsertBatch({
        variables: {
          batch: {
            code: batch,
            _supplier: supplier,
            _item: container._item,
          },
        },
      });
      const batchId = batchResult.data.batch._id;

      await mutateContainerBatch({
        variables: { batchId, notes },
      });
      toast({ title: intl.formatMessage({ id: "app.shared.save_success" }) });
      history.goBack();
    } catch (error) {
      onError(error);
    }
    dispatch(setLoading(false));
  }
  const theme = themes.default;

  // Build step sequence accordingly to HMI configuration
  const stepSequence = [0];
  if (!hmiConfiguration.SKIP_CONTAINER_SUPPLIER) stepSequence.push(1);
  if (!hmiConfiguration.SKIP_CONTAINER_NOTES) stepSequence.push(2);

  const incStep = (inc = 1) => {
    const newIndex =
      inc > 0
        ? Math.min(stepIndex + 1, stepSequence.length - 1)
        : Math.max(0, stepIndex - 1);
    setStepIndex(newIndex);
    setStep(stepSequence[newIndex]);
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Background head={<Header hasSessionInfo={false} hasUserInfo />}>
          {/* NAME */}
          {step === 0 && (
            <HmiField
              title={intl.formatMessage({
                id: "app.pages.application.container_batch.title",
              })}
              {...(stepIndex === stepSequence.length - 1
                ? { onSubmit: onSubmit }
                : { onNext: () => incStep() })}
              onBack={() => {
                history.goBack();
              }}
              field={
                <TextField
                  key="batch"
                  placeholder={batch}
                  defaultValue={batch}
                  onChange={setBatch}
                />
              }
            />
          )}
          {/* SUPPLIER */}
          {step === 1 && (
            <HmiField
              title={intl.formatMessage({
                id: "app.pages.application.container_supplier.title",
              })}
              {...(stepIndex === stepSequence.length - 1
                ? { onSubmit: onSubmit }
                : { onNext: () => incStep() })}
              onBack={() => {
                incStep(-1);
              }}
              field={
                <SelectField
                  key="supplier"
                  placeholder={supplier}
                  defaultValue={supplier}
                  onChange={setSupplier}
                  options={suppliers.map((s) => {
                    return { value: s._id, text: s.name };
                  })}
                />
              }
            />
          )}
          {/* NOTES */}
          {step === 2 && (
            <HmiField
              title={intl.formatMessage({
                id: "app.pages.application.notes.title",
              })}
              {...(stepIndex === stepSequence.length - 1
                ? { onSubmit: onSubmit }
                : { onNext: () => incStep() })}
              onBack={() => {
                incStep(-1);
              }}
              field={
                <TextareaField
                  key="notes"
                  placeholder={notes}
                  defaultValue={notes}
                  onChange={setNotes}
                />
              }
            />
          )}
        </Background>
      </ThemeProvider>
    </>
  );
}

ContainerBatch.propTypes = {};

export default ContainerBatch;
