import React, { useState } from "react";
import styled from "styled-components";

import buttons from "../../../../libs/themes";

const HoverableDiv = styled.div`
  position: relative;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
`;

const Hoverable = ({ children, text }) => {
  const [isHovering, setIsHovering] = useState(false);
  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };
  return (
    <>
      <HoverableDiv onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
        {children}
      </HoverableDiv>
      {isHovering && <HoverText text={text} />}
    </>
  );
};

const HoverDiv = styled.div`
  position: fixed;
  padding: 1.8rem;
  max-width: 70vw;
  left: 9.4rem;
  top: 20.4rem;
  z-index: 12;
  background-color: ${buttons.neutralGrey.buttons.bg};
  border-radius: 0.8rem;
`;

const HoverText = (params) => {
  return (
    <HoverDiv>
      <p className="title is-4">{params.text}</p>
    </HoverDiv>
  );
};

export default Hoverable;
