const Sentry = require("@sentry/browser");

function capture(err) {
  console.error(err);
  if (process.env.NODE_ENV !== "development") {
    Sentry.captureException(err);
  }
}

module.exports = capture;
