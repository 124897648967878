import PropTypes from "prop-types";
import React from "react";
import { useIntl } from "react-intl";
import { compose, withProps } from "recompose";
import themes from "../../../libs/themes";
import { MediumButton } from "../../Button";

import onAdd from "./DocsAdd.mutation";
import onRemove from "./DocsRemove.mutation";

function Upload3dScan({ phaseId, onAdd, onRemove, refetchDocs }) {
  const intl = useIntl();
  const [isAdding, setIsAdding] = React.useState(false);
  return (
    <MediumButton
      theme={themes.productionBlue.buttons}
      onClick={async () => {
        console.log("Fetching 3d scan");
        setIsAdding(true);
        await onAdd(phaseId, { type: "MATERIAL" }, refetchDocs, intl);
        setIsAdding(false);
      }}
      data-cy="application-button-delivery-3dscan"
      disabled={isAdding}
    >
      3D Scan
    </MediumButton>
  );
}
Upload3dScan.propTypes = {
  phaseId: PropTypes.string.isRequired,
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default compose(withProps({ onAdd, onRemove }))(Upload3dScan);
