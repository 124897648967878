import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";

import themes from "../../libs/themes";
import nativeChannel from "../../nativeChannel";
import Button from "../Button";
import OnScreenKeyboard from "../OnScreenKeyboard";

function NumberField({
  placeholder,
  onChange,
  defaultValue,
  isScannable = false,
  autofocus = false,
  disabled,
  cy,
  isError = false,
  innerRef,
  ...props
}) {
  const intl = useIntl();
  const inputEl = useRef(null);
  const [showKeyboard, setShowKeyboard] = useState(false);

  useEffect(() => {
    if (autofocus) {
      if (innerRef) {
        innerRef.current && innerRef.current.select();
      } else {
        inputEl && inputEl.current && inputEl.current.select();
      }
    }

    if (isScannable) {
      window.onBarcode = onBarcode;
      nativeChannel.on("barcode", onBarcode);
      return () => {
        window.onBarcode = null;
        nativeChannel.off("barcode", onBarcode);
      };
    }
  }, [inputEl, innerRef]);

  const { hmiConfiguration } = useSelector(({ config }) => config);
  const { BARCODE_SCANNER } = hmiConfiguration;

  function onBarcodeSearch() {
    nativeChannel.send("read_barcode");
  }

  async function onBarcode(barcode) {
    if (inputEl.current) inputEl.current.value = barcode;
    onChange(barcode);
  }

  return (
    <>
      <div className="columns">
        <div
          className={classNames(
            "column has-text-centered",
            isError && "animated shake duration400"
          )}
        >
          <div className="separator" />
          <input
            {...props}
            name="text-field"
            id="text-field"
            data-cy={cy ?? "application-text-input"}
            type="number"
            className="title is-1 is-double"
            placeholder={placeholder ?? "0"}
            defaultValue={defaultValue ?? ""}
            step="any"
            min="0"
            ref={innerRef ?? inputEl}
            onFocus={() => {
              if (innerRef && innerRef.current) {
                innerRef.current.select();
                setShowKeyboard(true);
              } else if (inputEl.current) {
                inputEl.current.select();
                setShowKeyboard(true);
              }
            }}
            onBlur={() => {
              setShowKeyboard(false);
            }}
            onInput={(event) => {
              var value = event.target.value;
              if (value === "") {
                onChange(null);
                return;
              }
              onChange(parseFloat(value));
            }}
            disabled={disabled}
            style={{
              color: "#455a64",
              width: "100%",
              border: "none",
              outline: "none",
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              borderBottomLeftRadius: 4,
              borderBottomRightRadius: 4,
            }}
          />
          {BARCODE_SCANNER && isScannable && (
            <Button
              isFullWidth
              theme={themes.default.buttons}
              onClick={onBarcodeSearch}
              data-cy="application-button-scan"
              style={{
                position: "absolute",
                right: 0,
                width: "27rem",
                height: "7.5rem",
                transform: "translate(-48px, 8px)",
              }}
            >
              <i className="icon-barcode" style={{ fontSize: "2.4rem" }} />
              <p className="title is-4">
                {intl.formatMessage({
                  id: "app.shared.scan_barcode",
                  defaultMessage: "Scan Barcode",
                })}
              </p>
            </Button>
          )}
        </div>
      </div>
      {showKeyboard && !disabled && (
        <OnScreenKeyboard
          inputNode={innerRef?.current ?? inputEl.current}
          layouts={["numeric"]}
        />
      )}
    </>
  );
}

NumberField.propTypes = {};

export default NumberField;
