import { useQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import React, { useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import { logout } from "../../../actions";
import Button from "../../../components/Button";
import SearchField from "../../../components/Fields/SearchField";
import { hasFeature } from "../../../components/HasFeature";
import Header from "../../../components/Header";
import Background from "../../../components/Layout";
import LoadingIndicator from "../../../components/LoadingIndicator";
import OnScreenKeyboard from "../../../components/OnScreenKeyboard";
import Title from "../../../components/Title";
import BackImage from "../../../images/arrow-back-gray-icn.svg";
import CenteredLayout from "../../../layouts/CenteredLayout/CenteredLayout.layout";
import themes from "../../../libs/themes";
import Job from "./components/Job";
const JOBS = loader("./ChooseOrder.query.graphql");

function ChooseOrder() {
  const intl = useIntl();
  const inputEl = useRef(null);
  const theme = themes.default;
  const [search, setSearch] = useState("");
  const [showKeyboard, setShowKeyboard] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { stationId, hmiConfiguration, flow, features } = useSelector(
    ({ config }) => config
  );
  const { user } = useSelector(({ user }) => ({
    user,
  }));
  const {
    ON_SCREEN_KEYBOARD,
    PRODUCTION_ORDER_SORT: sort,
    WELCOME_CHOOSE_ORDER,
  } = hmiConfiguration;

  const variables = {
    stationId,
    sort,
    populate: ["customer", "product"],
    workerId: user?.worker?._id ?? null,
  };
  const { data, loading, error } = useQuery(JOBS, { variables });
  if (loading && !data) {
    return (
      <CenteredLayout>
        <LoadingIndicator />
      </CenteredLayout>
    );
  }
  if (error) {
    return `Error! ${error.message}`;
  }
  const { jobs } = data;

  function filterer(job) {
    if (!job.phases || job.phases.length === 0) {
      return false;
    }
    if (search && search.length === 0) {
      return true;
    }
    const rxSearch = new RegExp(
      search.replace(/[^a-z0-9]/gi, "[^a-z0-9]"),
      "i"
    );
    if (rxSearch.test(job.name)) {
      return true;
    }
    if (rxSearch.test(job.code)) {
      return true;
    }
    if (rxSearch.test(job.salesOrderId)) {
      return true;
    }
    if (rxSearch.test(job.meta?.customerId)) {
      return true;
    }
    if (rxSearch.test(job.product?.code)) {
      return true;
    }
    if (rxSearch.test(job.product?.name)) {
      return true;
    }
    if (rxSearch.test(job.customer?.code)) {
      return true;
    }
    if (rxSearch.test(job.customer?.name)) {
      return true;
    }
    return false;
  }

  let parentJobs = jobs.map((job) => {
    var parentJob = job.flatParents[0];
    return {
      ...parentJob,
      phases: job.phases,
      customer: job.customer,
      phasesCount: job.phasesCount,
      available: true,
    };
  });

  // Remove duplicate jobs and sum phases
  parentJobs = parentJobs.reduce((acc, job) => {
    const index = acc.findIndex((j) => j._id === job._id);
    if (index === -1) {
      acc.push(job);
    } else {
      acc[index].phases = acc[index].phases.concat(job.phases);
      acc[index].phasesCount += 1;
    }
    return acc;
  }, []);

  let filtered = parentJobs.filter(filterer);

  function onBack() {
    if (flow === "STATION") {
      dispatch(logout());
      history.push(`/configuration/operator`);
    } else if (flow === "NEW_ORDER") {
      history.push(`/worker/dashboard`);
    } else if (flow === "ORDER") {
      history.push("/");
    } else {
      if (stationId) {
        history.push(`/configuration/station`);
      } else {
        history.push(`/configuration/add-operations`);
      }
    }
  }

  function onFocus() {
    if (!ON_SCREEN_KEYBOARD) {
      return;
    }
    setShowKeyboard(true);
    inputEl.current.select();
  }

  if (WELCOME_CHOOSE_ORDER) {
    if (user?.worker) {
      filtered = filtered.map((job) => {
        const phaseIsAvailable = job.phases.some((p) => {
          if (p._worker && user.worker._id !== p._worker) return false;

          if (
            !hasFeature("MULTI_USER", features) &&
            p.activeSessions.length > 0
          ) {
            const activeSession = p.activeSessions.find(
              (s) => s?.worker._id === user.worker._id
            );
            return !!activeSession;
          }

          return true;
        });
        if (phaseIsAvailable) return job;
        else
          return {
            ...job,
            available: false,
          };
      });
    }
  } else {
    if (flow === "DASHBOARD") {
      const newOrders = filtered
        .map((job) => {
          const availablePhases = job.phases.filter(
            (p) =>
              p._tookByWorkers === null ||
              !p._tookByWorkers.includes(user.worker._id)
          );

          return {
            ...job,
            phases: availablePhases,
          };
        })
        .filter((j) => j.phases.length > 0);

      filtered = newOrders;
    }
  }

  return (
      <ThemeProvider theme={theme}>
        <Background
          head={<Header hasUserInfo hasSessionInfo={false} />}
          foot={
            <div className="columns">
              <div className="column is-1">
                <Button isFullWidth onClick={onBack}>
                  <img src={BackImage} alt="" />
                </Button>
              </div>
              <div className="column"></div>
            </div>
          }
        >
            {/* HEAD */}
            <div className="columns">
              <div className="column has-text-centered">
                <h1 className="title is-1 w700">
                  <FormattedMessage
                    id="app.pages.configuration.orders.title"
                    defaultMessage="Choose order ({value, number} found)"
                    values={{ value: filtered.length }}
                  />
                </h1>
              </div>
            </div>
            <div className="columns">
              <div className="column is-6 is-offset-3">
                <SearchField
                  placeholder={intl.formatMessage({
                    id: "app.pages.configuration.orders.search.placeholder",
                    defaultMessage: "Search order, product or customer...",
                  })}
                  defaultValue={search}
                  onChange={(value) => setSearch(value)}
                  onFocus={onFocus}
                />
              </div>
            </div>
            {/* DATA */}
            <div className="columns">
              <div className="column is-10 is-offset-1">
                {filtered.map((job) => (
                  <Job
                    user={user}
                    key={job._id}
                    job={job}
                    phases={job.phases}
                  />
                ))}
                {filtered.length === 0 && (
                  <Title.H2>
                    <FormattedMessage
                      id="app.pages.configuration.orders.no_order"
                      defaultMessage="There is no job, contact responsible"
                    />
                  </Title.H2>
                )}
              </div>
            </div>
            {showKeyboard && <OnScreenKeyboard inputNode={inputEl.current} />}
        </Background>
      </ThemeProvider>
  );
}

export default ChooseOrder;
