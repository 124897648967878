import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";

import Avatar from "../../../../components/Avatar";

const StyledWrapper = styled.div`
  .image {
    margin-bottom: 0.8rem;
  }

  .image,
  a {
    width: 12.8rem;
    height: 12.8rem;
  }
`;

function Station({ station, onClick }) {
  const total = station.jobs.length;
  return (
    <StyledWrapper className="column is-3 has-text-centered">
      <figure className="image is-relative">
        <a
          onClick={onClick}
          data-cy="configuration-stations-station"
          data-cy-id={station._id}
        >
          <Avatar size="12.8rem" name={station.name} src={station.image} />
        </a>
      </figure>
      <h4 className="title is-4 w500 has-text-centered">{station.name}</h4>
      <p className="subtitle is-5 fg-secondary">
        <FormattedMessage
          id="app.shared.order_assigned"
          defaultMessage="{total, plural, =0 {No order assigned} one {{total, number} order assigned} other {{total, number} orders assigned}}"
          values={{ total }}
        />
      </p>
    </StyledWrapper>
  );
}

Station.propTypes = {
  onClick: PropTypes.func.isRequired,
  station: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    image: PropTypes.string,
    jobs: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
};
export default Station;
