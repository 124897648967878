import { useMutation, useQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import Button from "../../../../components/Button";
import LoadingIndicator from "../../../../components/LoadingIndicator";
import StepHeader from "../../../../components/StepHeader";
import { SmallTag } from "../../../../components/Tag";
import Title from "../../../../components/Title";
import onError from "../../../../libs/error-logger";
import themes from "../../../../libs/themes";
import Hoverable from "../hoverable";
import BatchProductivity from "./components/BatchProductivity";
import SessionProductivity from "./components/SessionProductivity";
import SessionTimer from "./components/SessionTimer";

const PRODUCTION = loader("./ProductionBody.query.graphql");
const MANUAL_CYCLES = loader("./ManualCycles.mutation.graphql");

/**
 * Production page
 *
 * @param stepName
 * @constructor
 */

const Column = styled.div`
  max-width: 100%;
`;

const CyclesButton = styled(Button)`
  width: 12rem;
  height: 12rem;
  border-radius: 8rem;
  color: ${themes.productionBlue.bg};
  border: 1rem solid rgba(0, 0, 0, 0.2);
  font-size: 6rem;
  div {
    transform: translateY(-0.3rem);
  }
`;

function ProductionBody({ stepName = "PRODUCTION" }) {
  const { phaseId, sessionId } = useParams();
  const variables = { phaseId, sessionId };
  const { data, loading, error } = useQuery(PRODUCTION, {
    variables,
  });
  const [mutate] = useMutation(MANUAL_CYCLES, { variables });
  if (loading && !data) {
    return <LoadingIndicator />;
  }
  if (error) {
    return `Error! ${error.message}`;
  }
  const { session, phase } = data;

  const machineId = session._machine;

  async function addCycles(value) {
    try {
      let manualCycles = session.cycles + value;
      if (manualCycles >= 0) {
        await mutate({
          variables: { cycles: manualCycles, sessionId: session._id },
        });
      }
    } catch (e) {
      onError(e);
    }
  }
  return (
    <>
      <div className="columns">
        <Column className="column">
          <SmallTag isUpperCase isLight theme={themes.productionBlue.buttons}>
            {stepName || (
              <FormattedMessage
                id="app.shared.production"
                defaultMessage="Production"
              />
            )}
          </SmallTag>
          <Hoverable text={phase.sort + " :: " + phase.name}>
            <Title.H1 isEllipsed>
              {phase.sort} :: {phase.name}
            </Title.H1>
          </Hoverable>
          <StepHeader type="PRODUCTION" />
        </Column>
      </div>

      <div className="columns is-vcentered">
        {/* CYCLES BUTTON DOWN */}
        {!machineId && !session.hasSerialnumbers && (
          <Column className="column is-narrow">
            <CyclesButton
              isFullWidth
              theme={themes.primary.buttons}
              onClick={() => addCycles(-1.0)}
              data-cy="application-button-remove-cycle"
              disabled={session.cycles === 0}
            >
              <div>-</div>
            </CyclesButton>
          </Column>
        )}

        {/* SESSION TIMER */}
        <div className="column has-text-centered">
          <SessionTimer
            production={session.timers.PRODUCTION}
            total={session.timers.TOTAL || 0}
          />
        </div>
        {/* BATCH PRODUCTIVITY */}
        <div className="column has-text-centered">
          <BatchProductivity
            produced={phase.produced || 0}
            percentage={phase.percentage || 0}
            qty={phase.qty}
          />
        </div>
        {/* SESSION PRODUCTIVITY */}
        <div className="column has-text-centered">
          <SessionProductivity
            time={session.timers.PRODUCTION || 0}
            produced={session.produced || 0}
            average={phase.average}
            active
          />
        </div>

        {/* CYCLES BUTTON UP */}
        {!machineId && !session.hasSerialnumbers && (
          <Column className="column is-narrow is-vertical">
            <CyclesButton
              isFullWidth
              theme={themes.primary.buttons}
              onClick={() => addCycles(1.0)}
              data-cy="application-button-add-cycle"
            >
              <div>+</div>
            </CyclesButton>
          </Column>
        )}
      </div>
    </>
  );
}

ProductionBody.propTypes = {
  stepName: PropTypes.string,
};

export default ProductionBody;
