import { ApolloClient, HttpLink, InMemoryCache, split } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { createClient } from "graphql-ws";

import Cookies from "js-cookie";

import config from "../config";
import store from "../store";

console.log(
  "Starting graphql on",
  config.graphql.api,
  "with websockets on",
  config.graphql.ws
);

const httpLink = new HttpLink({
  uri: config.graphql.api,
});
const wsLink = new GraphQLWsLink(
  createClient({
    url: config.graphql.ws,
  })
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

const middlewareLink = setContext(() => {
  const state = store.getState();
  let token = state?.user.token;
  const clientTokenCookie = Cookies.get("clientToken");
  if (!token) {
    token = clientTokenCookie;
  }
  if (token) {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        clientToken: clientTokenCookie,
      },
    };
  }
  return {
    headers: {},
  };
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: "cache-and-network",
    // errorPolicy: 'all',
  },
};

const client = new ApolloClient({
  link: middlewareLink.concat(splitLink),
  cache: new InMemoryCache(),
  defaultOptions,
});

export default client;
