import classNames from "classnames";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { MediumButton } from "../../../../components/Button";

import Header from "../../../../components/Header";
import Background from "../../../../components/Layout";
import StepHeader from "../../../../components/StepHeader";
import { SmallTag } from "../../../../components/Tag";
import Title from "../../../../components/Title";
import themes, { buttons } from "../../../../libs/themes";

function Produced() {
  const history = useHistory();
  const theme = themes.default;
  const { phaseId, sessionId } = useParams();
  const { delivery } = useSelector(({ delivery, config }) => ({
    delivery,
    config,
  }));
  const [produced, setProduced] = useState(delivery.session.produced);

  const dispatch = useDispatch();

  function onBack() {
    if (sessionId) {
      history.push(`/worker/manual-session/delivery/${phaseId}/${sessionId}`);
    } else {
      history.push(`/worker/manual-session/delivery/${phaseId}`);
    }
  }

  function onSubmit() {
    console.log("produced", produced);
    dispatch(setProduced(produced));
    if (sessionId) {
      history.push(`/worker/manual-session/scrap/${phaseId}/${sessionId}`);
    } else {
      history.push(`/worker/manual-session/scrap/${phaseId}`);
    }
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <Background head={<Header hasSessionInfo={false} hasUserInfo={true} />}>
          <>
            <div className="columns">
              <div className="column">
                <SmallTag isUpperCase isLight theme={buttons.neutralGrey}>
                  <Choose>
                    <When condition={delivery.status === "INTERRUPT"}>
                      <FormattedMessage
                        id="app.shared.delivery.partial"
                        defaultMessage="Partial delivery"
                      />
                    </When>
                    <When condition={delivery.status === "DONE"}>
                      <FormattedMessage
                        id="app.shared.delivery.final"
                        defaultMessage="Final delivery"
                      />
                    </When>
                    <Otherwise>
                      <FormattedMessage
                        id="app.shared.delivery"
                        defaultMessage="Delivery"
                      />
                    </Otherwise>
                  </Choose>
                </SmallTag>
                <Title.H1>
                  <FormattedMessage
                    id="app.pages.application.produced.title"
                    defaultMessage="How many pieces?"
                  />
                </Title.H1>
                <StepHeader type="INPUT" />
              </div>
              <div className={classNames("column", "is-3")}>
                <div className="columns">
                  <div className="column">
                    <MediumButton
                      isFullWidth
                      theme={buttons.default}
                      onClick={onBack}
                      data-cy="application-button-back"
                    >
                      <FormattedMessage
                        id="app.shared.back"
                        defaultMessage="Back"
                      />
                    </MediumButton>
                  </div>
                  <div className="column">
                    <MediumButton
                      isFullWidth
                      theme={buttons.productionBlue}
                      onClick={onSubmit}
                      data-cy="application-button-ok"
                    >
                      <FormattedMessage
                        id="app.shared.ok"
                        defaultMessage="Ok"
                      />
                    </MediumButton>
                  </div>
                </div>
              </div>
            </div>
            {/*<OnScreenKeyboard inputNode={inputNode} layouts={['numeric']} />*/}
          </>
        </Background>
      </ThemeProvider>
    </>
  );
}

Produced.propTypes = {};

export default Produced;
