import { useQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import { resetTeam } from "../../../../actions";
import Button from "../../../../components/Button";
import Header from "../../../../components/Header";
import Background from "../../../../components/Layout";
import LoadingIndicator from "../../../../components/LoadingIndicator";
import Subtitle from "../../../../components/Subtitle";
import Title from "../../../../components/Title";
import BackImage from "../../../../images/arrow-back-gray-icn.svg";
import CenteredLayout from "../../../../layouts/CenteredLayout";
import intl from "../../../../libs/format-message";
import themes from "../../../../libs/themes";
import toast from "../../../../libs/toast";
import OperatorsGrouped from "./components/OperatorsGrouped";

const OPERATORS = loader("./AdvancedTeam.query.graphql");

/**
 * ChooseTeam
 *
 * @returns {*}
 * @constructor
 */
function AdvancedTeam() {
  const theme = themes.default;
  const dispatch = useDispatch();
  const [edited] = useState(false);
  const { user, config } = useSelector(({ user, config }) => ({
    user,
    config,
  }));
  const { stationId } = config;
  const history = useHistory();
  const { index, jobId, phaseId } = useParams();

  const { data, loading, error } = useQuery(OPERATORS, {
    variables: { stationId },
  });
  if (loading && !data) {
    return (
      <CenteredLayout>
        <LoadingIndicator />
      </CenteredLayout>
    );
  }
  if (error) {
    return `Error! ${error.message}`;
  }
  const { operators } = data;

  return (
    <>
      <ThemeProvider theme={theme}>
        <Background
          head={<Header hasUserInfo hasSessionInfo={false} />}
          foot={
            <div className="columns">
              <div className="column is-1">
                <Button
                  isFullWidth
                  onClick={() =>
                    history.push(
                      `/configuration/advanced/${
                        index ? "team" : "menu"
                      }/${jobId}/${phaseId}`
                    )
                  }
                  data-cy="application-button-back"
                >
                  <img src={BackImage} alt="" />
                </Button>
              </div>

              {index === undefined ? (
                <div className="column is-4 is-offset-3">
                  <Button
                    isFullWidth
                    theme={theme.buttons}
                    onClick={() => dispatch(resetTeam())}
                    data-cy="configuration-team-reset"
                  >
                    <FormattedMessage
                      id="app.shared.clear"
                      defaultMessage="Clear"
                    />
                  </Button>
                </div>
              ) : (
                <div className="column is-4 is-offset-3">
                  <Button
                    isFullWidth
                    theme={theme.buttons}
                    onClick={() => {
                      if (edited) {
                        toast({
                          title: intl.formatMessage({
                            id: "app.shared.save_success",
                            defaultMessage: "Information saved successfully",
                          }),
                          icon: "success",
                        });
                      }
                      history.push(
                        `/configuration/definition/${jobId}/${phaseId}`
                      );
                    }}
                    data-cy="configuration-team-reset"
                  >
                    <FormattedMessage
                      id="app.shared.done"
                      defaultMessage="Fatto"
                    />
                  </Button>
                </div>
              )}
            </div>
          }
        >
          <>
            <div className="columns">
              <div className="column is-full has-text-centered">
                <Title.H1>
                  <FormattedMessage
                    id="app.pages.configuration.advanced.team.title"
                    defaultMessage="How the team will be composed"
                  />
                </Title.H1>
                <Subtitle.S3 theme={themes.secondary}>
                  <FormattedMessage
                    id="app.pages.configuration.advanced.team.subtitle"
                    defaultMessage="Choose members, clicking on them"
                  />
                </Subtitle.S3>
                <OperatorsGrouped
                  operators={operators.filter((o) => o._id !== user.worker._id)}
                />
                {operators.filter((o) => o._id !== user.worker._id).length ===
                  0 && (
                  <Title.H2>
                    <FormattedMessage
                      id="app.pages.configuration.advanced.team.empty"
                      defaultMessage="Sorry, no operator available"
                    />
                  </Title.H2>
                )}
              </div>
            </div>
          </>
        </Background>
      </ThemeProvider>
    </>
  );
}

AdvancedTeam.defaultProps = {
  loading: true,
};
AdvancedTeam.propTypes = {
  loading: PropTypes.bool,
};

export default AdvancedTeam;
