import React, { useEffect, useState } from "react";
import config from "../config";

const buildDifferentThan = (latest, current) => {
  if (latest.version != current.version || latest.build != current.build) {
    return true;
  } else {
    return false;
  }
};

function withClearCache(Component) {
  function ClearCacheComponent(props) {
    const [isLatestBuildDate, setIsLatestBuildDate] = useState(false);

    function checkVersion() {
      fetch("/meta.json")
        .then((response) => response.json())
        .then((meta) => {
          const latest = meta;
          console.log("latest", latest);
          const current = {
            version: config.version,
            build: config.build,
          };

          const shouldForceRefresh = buildDifferentThan(latest, current);

          if (shouldForceRefresh) {
            setIsLatestBuildDate(false);
            refreshCacheAndReload();
          } else {
            setIsLatestBuildDate(true);
          }
        })
        .catch((error) => {
          console.log(error);
          setIsLatestBuildDate(true);
        });
    }

    const refreshCacheAndReload = () => {
      if (caches) {
        // Service worker cache should be cleared with caches.delete()
        caches.keys().then((names) => {
          for (const name of names) {
            caches.delete(name);
          }
        });
      }
      // delete browser cache and hard reload
      window.location.reload(true);
    };

    useEffect(() => {
      checkVersion();
      const interval = setInterval(() => {
        checkVersion();
      }, config.cacheReloadTimeout);
      return () => clearInterval(interval);
    }, []);

    return (
      <React.Fragment>
        {isLatestBuildDate ? <Component {...props} /> : null}
      </React.Fragment>
    );
  }

  return ClearCacheComponent;
}

export default withClearCache;
