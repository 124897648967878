import ErrorTracking from "@agile-factory/module-error-tracking-browser";
import React, { useEffect } from "react";
import { ApolloProvider } from "@apollo/client";
import { IntlProvider } from "react-intl";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/lib/integration/react";

import FullPageLoader from "../components/FullPageLoader";
import config from "../config";
import { language, messages } from "../config/localization";
import graphql from "../libs/graphql";
import store from "../store";
import Routes from "./Routes";
import Notifications from "../components/Notifications/Notifications";

const persistor = persistStore(store, null, () => {
  console.log("Store rehydrated");
});

/**
 * React Application
 *
 * @returns {*}
 * @constructor
 */
function Application() {
  useEffect(() => {
    ErrorTracking.initialize({
      customer: config.customer,
      dsn: config.sentry,
      release: config.build,
      environment: config.env,
    });
  });
  return (
    <PersistGate
      persistor={persistor}
      loading={
        <IntlProvider locale={language} messages={messages}>
          <FullPageLoader />
        </IntlProvider>
      }
    >
      <IntlProvider locale={language} messages={messages}>
        <ApolloProvider client={graphql}>
          <Provider store={store}>
            <Routes />
            <div id="modal-container" />
            <Notifications />
          </Provider>
        </ApolloProvider>
      </IntlProvider>
    </PersistGate>
  );
}
Application.propTypes = {};

export default Application;
