import classNames from "classnames";
import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";

import themes from "../../libs/themes";
import nativeChannel from "../../nativeChannel";
import Button from "../Button";

function SelectField({
  placeholder,
  onChange,
  defaultValue,
  options,
  isError = false,
  isScannable = false,
}) {
  const intl = useIntl();
  const inputEl = useRef(null);
  useEffect(() => {
    if (isScannable) {
      window.onBarcode = onBarcode;
      nativeChannel.on("barcode", onBarcode);
      return () => {
        window.onBarcode = null;
        nativeChannel.off("barcode", onBarcode);
      };
    }
  }, [inputEl]);
  const { hmiConfiguration } = useSelector(({ config }) => config);

  const { BARCODE_SCANNER } = hmiConfiguration;

  function onBarcodeSearch() {
    nativeChannel.send("read_barcode");
  }

  async function onBarcode(barcode) {
    if (options.find((option) => option.value == barcode) != null) {
      onChange(barcode);
    }
  }

  return (
    <>
      <div className="columns">
        <div
          className={classNames(
            "column has-text-centered",
            isError && "animated shake duration400"
          )}
        >
          <div className="separator" />
          <select
            ref={inputEl}
            defaultValue={defaultValue ?? ""}
            onChange={(e) => onChange(e.target.value)}
            data-cy="application-select-supplier"
            className="input title is-1 is-double"
            style={{
              color: "#455a64",
              width: "100%",
              height: "auto",
              padding: "1.25rem 2.5rem 1.25rem 1.5rem",
              border: "none",
              outline: "none",
              backgroundColor: "#cfd8dc",
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              borderBottomLeftRadius: 4,
              borderBottomRightRadius: 4,
            }}
          >
            <option disabled value="">
              {placeholder}
            </option>
            {options.map((option, index) => (
              <option key={"opt" + index} value={option.value}>
                {option.text}
              </option>
            ))}
          </select>
          {BARCODE_SCANNER && isScannable && (
            <Button
              isFullWidth
              theme={themes.default.buttons}
              onClick={onBarcodeSearch}
              data-cy="application-button-scan"
              style={{
                position: "absolute",
                right: 0,
                width: "27rem",
                height: "7.5rem",
                transform: "translate(-48px, 8px)",
              }}
            >
              <i className="icon-barcode" style={{ fontSize: "2.4rem" }} />
              <p className="title is-4">
                {intl.formatMessage({
                  id: "app.shared.scan_barcode",
                  defaultMessage: "Scan Barcode",
                })}
              </p>
            </Button>
          )}
        </div>
      </div>
    </>
  );
}

SelectField.propTypes = {};

export default SelectField;
