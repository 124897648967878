export function getHours(value, leading = true) {
  const hours = Math.floor(value / 60);
  if (leading) {
    if (hours < 10) {
      return `0${hours}`;
    }
    return hours || "00";
  }
  return hours || "0";
}

export function getMinutes(value) {
  const minutes = Math.floor((value % 60) / 60);
  if (minutes < 10) {
    return `0${minutes}`;
  }
  return minutes || "00";
}

/**
 * Converts time to HH:MM
 * @param time
 * @returns {string}
 */
function minutesToHHMM(time) {
  const hh = Math.floor(time / 60);
  const mm = Math.floor(time % 60);
  return `${hh.toString().padStart(2, "0")}:${mm.toString().padStart(2, "0")}`;
}

export default minutesToHHMM;
