import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useSubscription } from "@apollo/client";
import classNames from "classnames";

import Avatar from "../../../../../components/Avatar";
import { buttons } from "../../../../../libs/themes";

import { loader } from "graphql.macro";
const MACHINE_UPDATED = loader("./Machine.subscription.graphql");

const StyledWrapper = styled.div`
  position: relative;
  &.disabled {
  }
  .image {
    margin-bottom: 0.8rem;
    width: 18rem;
  }

  .status {
    transition: opacity 250ms ease-in-out;
    opacity: 0;

    &.is-selected {
      position: absolute;
      top: -1.75rem;
      right: 0.15rem;
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
      border: 0.3rem solid rgba(245, 245, 245, 0.5);
      opacity: 1;
      transition: opacity 250ms ease-in-out;
      color: ${buttons.checkGreen.fg};
      background-color: ${buttons.checkGreen.bg};
    }
  }
`;

function Machine({ machine, onClick }) {
  const { machineId } = useSelector(({ config }) => config);
  const variables = { machineId };
  useSubscription(MACHINE_UPDATED, { variables });
  const isSelected = machineId === machine._id;
  return (
    <StyledWrapper className="column is-3 has-text-centered">
      <figure className="image" style={{ marginBottom: 8 }}>
        <a
          onClick={onClick}
          data-cy="configuration-advanced-machines-machine"
          data-cy-id={machine._id}
        >
          <Avatar size="12.8rem" name={machine.name} src={machine.image} />
        </a>
        <span className={classNames("status", isSelected && "is-selected")} />
      </figure>
      <h4 className="title is-4 w500 has-text-centered">{machine.name}</h4>
      <p className="subtitle is-5 fg-secondary has-text-centered">
        {machine.status || "UNKNOWN"}
      </p>
    </StyledWrapper>
  );
}

Machine.propTypes = {
  onClick: PropTypes.func.isRequired,
  machine: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    image: PropTypes.string,
    status: PropTypes.string.isRequired,
  }).isRequired,
};
export default Machine;
