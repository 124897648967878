/*
  CUSTOMIZATIONS FOR IT-NEXT
 */
import en from "./english.json";
import it from "./italian.json";
import ru from "./russian.json";

export default {
  en,
  it,
  ru,
};
