const Sentry = require("@sentry/browser");

/**
 * Initialize Storage
 */
function initialize(config) {
  if (
    process.env.NODE_ENV !== "development" &&
    process.env.NODE_ENV !== "test"
  ) {
    Sentry.init({
      dsn: config.dsn,
      release: config.build,
      environment: config.env,
    });
    Sentry.configureScope((scope) => {
      scope.setTag("customer", config.customer);
      scope.setTag("serial_no", config.serialNo);
    });
  }
}

module.exports = initialize;
