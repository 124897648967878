import React from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import moment from "moment";

const UPDATE_INTERVAL = 500;

class SessionProductivity extends React.Component {
  static propTypes = {
    time: PropTypes.number,
    children: PropTypes.node,
  };
  static defaultProps = {
    time: 0,
  };
  state = {
    now: 0,
    componentMountedAt: moment(),
  };
  UNSAFE_componentWillMount() {
    this.startTimer();
  }
  componentWillUnmount() {
    this.stopTimer();
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.time !== this.props.time) {
      this.setState({ componentMountedAt: moment() });
    }
  }
  startTimer() {
    this.loadInterval = setInterval(() => {
      this.forceUpdate();
    }, UPDATE_INTERVAL);
  }
  stopTimer() {
    this.loadInterval && clearInterval(this.loadInterval);
    this.loadInterval = false;
  }
  /**
   * Calculate external circle
   **/
  calculatePercentage(produced, value) {
    return produced >= value ? 100 : Math.round((produced * 100) / value);
  }
  getSeconds() {
    const { time } = this.props;
    const { componentMountedAt } = this.state;
    const duration = moment
      .duration(moment().diff(moment(componentMountedAt)))
      .asSeconds();
    const seconds = duration + time;
    return Math.round(seconds);
  }
  render() {
    const { intl, produced, average } = this.props;

    const seconds = this.getSeconds();

    // avg : 3600 = x : seconds --> (avg*seconds)/3600
    const value = Math.round((average * seconds) / 3600);

    const percentage = this.calculatePercentage(produced, value);

    return (
      <div className="single-chart">
        <svg viewBox="0 0 36 36" className="circular-chart shift">
          <path
            className="circle-bg"
            d="M18 2.0845
              a 15.9155 15.9155 0 0 1 0 31.831
              a 15.9155 15.9155 0 0 1 0 -31.831"
          />
          {percentage > 0 && (
            <path
              className="circle"
              strokeDasharray={`${percentage || 0}, 100`}
              d="M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831"
            />
          )}
          <text x="18" y="15.2" className="circle-title">
            {intl.formatMessage({ id: "app.shared.session" })}
          </text>
          <text
            x="18"
            y="20"
            className="circle-value"
            data-cy="application-session-produced"
          >
            {intl.formatNumber(produced)}
          </text>
          <line
            x1="10"
            x2="26"
            y1="21.5"
            y2="21.5"
            style={{ stroke: "#cfd8dc", strokeWidth: ".02rem" }}
          />
          <text x="18" y="25" className="circle-subtitle">
            {intl.formatMessage({ id: "app.shared.theoretical" })}
          </text>
          <text x="18" y="28" className="circle-subvalue">
            <Choose>
              <When condition={average}>{intl.formatNumber(value)}</When>
              <Otherwise>
                {intl.formatMessage({ id: "app.shared.na" })}
              </Otherwise>
            </Choose>
          </text>
        </svg>
      </div>
    );
  }
}

export default injectIntl(SessionProductivity);
