import intl from "./format-message";
import toast from "./toast";

/**
 * Shows an error message
 * @param err
 */
// TODO: log somewhere outside...
function onWarning(err) {
  toast({
    icon: "warning",
    title: intl.formatMessage({
      id: "app.shared.warning",
      defaultMessage: "Warning",
    }),
    text: err.message,
  });
}

export default onWarning;
