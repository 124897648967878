import classNames from "classnames";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";

import Avatar from "../../../../../components/Avatar";
import confirmAction from "../../../../../libs/confirm_action";
import { buttons } from "../../../../../libs/themes";
import toast from "../../../../../libs/toast";
import ContainerMovementType from "./ContainerMovementType";

const StyledWrapper = styled.div`
  position: relative;

  &.disabled {
    opacity: 0.5;
  }

  .status {
    transition: opacity 250ms ease-in-out;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .image {
      height: 2rem;
      width: 2rem;
    }

    &.is-output {
      position: absolute;
      top: -1.75rem;
      right: 0.15rem;
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
      border: 0.3rem solid rgba(245, 245, 245, 0.5);
      opacity: 1;
      transition: opacity 250ms ease-in-out;
      color: ${buttons.productionBlue.fg};
      background-color: ${buttons.productionBlue.bg};
    }

    &.is-input {
      position: absolute;
      top: -1.75rem;
      right: 0.15rem;
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
      border: 0.3rem solid rgba(245, 245, 245, 0.5);
      opacity: 1;
      transition: opacity 250ms ease-in-out;
      color: ${buttons.materialViolet.fg};
      background-color: ${buttons.materialViolet.bg};
    }

    &.is-scrap {
      position: absolute;
      top: -1.75rem;
      right: 0.15rem;
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
      border: 0.3rem solid rgba(245, 245, 245, 0.5);
      opacity: 1;
      transition: opacity 250ms ease-in-out;
      color: ${buttons.errorRed.fg};
      background-color: ${buttons.errorRed.bg};
    }
  }
  > .image {
    margin-bottom: 0.8rem;
    width: 9.6rem;
    height: 9.6rem;
  }
  .image {
    width: 9.6rem;
    height: 9.6rem;
    margin-bottom: 6px;
  }
  a {
    width: 9.6rem;
    height: 9.6rem;
  }
`;
function Container({ container, containerIds, onClick, session }) {
  const intl = useIntl();

  let type = "normal";
  if (session._containerOutput === container?._id) {
    type = "output";
  } else if (session._containerScrap === container?._id) {
    type = "scrap";
  } else if (
    containerIds != undefined &&
    containerIds.indexOf(container?._id) != -1
  ) {
    type = "input";
  }

  let currentContainerIds =
    session.pickingItem?.containers?.map((container) => container._id) || [];
  const enabled =
    container.activeSession == null ||
    currentContainerIds.includes(container?._id);

  let containerItemName = container.item
    ? container.item.code &&
      container.item.name &&
      container.item.name !== container.item.code
      ? container.item.code + " :: " + container.item.name
      : container.item.name ?? container.item.code
    : "-";

  return (
    <StyledWrapper
      className={`column is-3 has-text-centered ${!enabled && "disabled"}`}
    >
      {/* IMAGE */}
      <figure className="image">
        <a
          onClick={() => {
            if (!enabled) {
              toast({
                title: intl.formatMessage({
                  id: "app.pages.application.container_choose.already_in_use",
                  defaultMessage: "Warning, container already in use",
                }),
                icon: "warning",
              });
              return;
            }
            if (type != "normal" && type != "input") {
              return confirmAction({
                confirm: () => {
                  onClick(container?._id);
                },
                options: {
                  text: intl.formatMessage({
                    id: "app.pages.application.container_choose.already_in_use.confirm",
                    defaultMessage: "Warning, container already in use",
                  }),
                },
              });
            }
            return onClick(container?._id);
          }}
          data-cy="application-containers-container"
          data-cy-id={container?._id}
        >
          <Avatar size="9.6rem" name={container.name} src={container.image} />
        </a>
        <div className={classNames("status", `is-${type}`)}>
          <ContainerMovementType type={type} size="24rem" />
        </div>
      </figure>
      {/* CURRENT LEVEL */}
      <p className="subtitle is-6 fg-secondary has-text-centered">
        <FormattedMessage
          id="app.pages.application.container_choose.quantity"
          defaultMessage="Quantity: {quantity}"
          values={{
            quantity: container.current,
          }}
        />
      </p>
      {/* NAME */}
      <h6 className="title is-6 w500 has-text-centered">{container.name}</h6>
      {/* BATCH */}
      <p className="subtitle is-6 fg-secondary has-text-centered is-marginless-bottom">
        {container.batch?.code || "-"}
      </p>
      {/* ITEM */}
      {container.item && (
        <p className="subtitle is-6 fg-secondary has-text-centered is-marginless-top">
          {containerItemName}
        </p>
      )}
    </StyledWrapper>
  );
}

export default Container;
