import { useMutation, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import { setLoading } from "../../../../actions";
import Button from "../../../../components/Button";
import Header from "../../../../components/Header";
import Background from "../../../../components/Layout";
import Title from "../../../../components/Title";
import onError from "../../../../libs/error-logger";
import themes from "../../../../libs/themes";
import toast from "../../../../libs/toast";

import { loader } from "graphql.macro";
import LoadingIndicator from "../../../../components/LoadingIndicator";
import CenteredLayout from "../../../../layouts/CenteredLayout/CenteredLayout.layout";
import GenericError from "../../../errors/GenericError";
import ContainerItemStep1 from "./components/ContainerItemStep1";
import ContainerItemStep2 from "./components/ContainerItemStep2";
const CONTAINER_ITEM = loader("./ContainerItem.query.graphql");
const CONTAINER_ITEM_MUTATION = loader("./ContainerItem.mutation.graphql");

function ContainerItem() {
  const intl = useIntl();
  const [step, setStep] = useState(1);
  const [itemId, setItemId] = useState();
  const [notes, setNotes] = useState();
  const theme = themes.default;
  const { containerId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const variables = { containerId };
  const { loading, error, data } = useQuery(CONTAINER_ITEM, { variables });
  const [mutate] = useMutation(CONTAINER_ITEM_MUTATION, { variables });

  if (loading && !data) {
    return (
      <CenteredLayout>
        <LoadingIndicator />
      </CenteredLayout>
    );
  }
  if (error) {
    return <GenericError error={error.message} />;
  }
  const { container } = data;

  async function onSubmit() {
    dispatch(setLoading(true));
    try {
      console.log("itemId", itemId);
      console.log("notes", notes);
      await mutate({ variables: { itemId, notes } });
      toast({ title: intl.formatMessage({ id: "app.shared.save_success" }) });
      history.goBack();
    } catch (error) {
      onError(error);
    }
    dispatch(setLoading(false));
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <Background
          head={
            <Header hasSessionInfo={false} hasUserInfo hasClock={false}>
              <div className="column is-4 has-text-centered">
                <Title.H3>{container.name}</Title.H3>
              </div>
            </Header>
          }
          foot={
            step === 1 && (
              <div className="columns">
                <div className="column is-3">
                  <Button
                    isFullWidth
                    onClick={() => history.goBack()}
                    data-cy="application-button-back"
                  >
                    <FormattedMessage
                      id="app.shared.cancel"
                      defaultMessage="Cancel"
                    />
                  </Button>
                </div>
                <div className="column is-3 is-offset-6">
                  <Button
                    isFullWidth
                    onClick={() => {
                      setItemId(null);
                      setStep(2);
                    }}
                  >
                    <FormattedMessage
                      id="app.shared.skip"
                      defaultMessage="Skip"
                    />
                  </Button>
                </div>
              </div>
            )
          }
        >
          {step === 1 && (
            <ContainerItemStep1
              container={container}
              setStep={setStep}
              setItemId={setItemId}
              itemId={itemId}
            />
          )}
          {step === 2 && (
            <ContainerItemStep2
              container={container}
              setStep={setStep}
              setNotes={setNotes}
              notes={notes}
              onSubmit={onSubmit}
            />
          )}
        </Background>
      </ThemeProvider>
    </>
  );
}

ContainerItem.propTypes = {};

export default ContainerItem;
