// User reducer
export const SET_USER = "SET_USER";
export const LOGOUT = "LOGOUT";

export function logout() {
  return { type: LOGOUT };
}
export function setUser(user = { worker: null, token: null }) {
  return { type: SET_USER, user };
}
