import {
  SET_CONTAINER_ID,
  SET_CONTAINER_PICKING_ID,
  SET_CONTAINER_NAME,
  SET_CONTAINER_QTY,
  SET_CONTAINER_SCRAP,
  SET_CONTAINER_BATCH,
  SET_CONTAINER_ITEM,
  SET_CONTAINER_NOTES,
  SET_CONTAINER_SUPPLIER,
  SET_CONTAINER_SERIALNUMBER_CODE,
  SET_CONTAINER_ERROR,
  SET_CONTAINER_ACTION,
  SET_CONTAINER_ACTION_ORIGIN,
  RESET_CONTAINER_CONFIG,
} from "../actions";

const INITIAL_STATE = {
  error: null,
  id: null,
  pickingId: null,
  name: null,
  qty: null,
  notes: null,
  scrap: null,
  batch: null,
  supplier: null,
  action: null,
  origin: null,
};
/**
 * Delivery Reducer
 * Used for storing informations about Delivery
 *
 * @param state
 * @param action
 * @returns {*}
 */
const reducer = (state = INITIAL_STATE, { type, value }) => {
  switch (type) {
    case RESET_CONTAINER_CONFIG:
      return { ...INITIAL_STATE, action: state.action, origin: state.origin };

    case SET_CONTAINER_ERROR:
      return { ...state, error: value };
    case SET_CONTAINER_ID:
      if (value) {
        return { ...state, id: value };
      }
      return { ...state, id: null };
    case SET_CONTAINER_PICKING_ID:
      if (value) {
        return { ...state, pickingId: value };
      }
      return { ...state, pickingId: null };
    case SET_CONTAINER_ACTION:
      if (value) {
        return { ...state, action: value };
      }
      return { ...state, name: null };
    case SET_CONTAINER_ACTION_ORIGIN:
      if (value) {
        return { ...state, origin: value };
      }
      return { ...state, name: null };
    case SET_CONTAINER_NAME:
      if (value) {
        return { ...state, name: value };
      }
      return { ...state, name: null };
    case SET_CONTAINER_QTY:
      if (value) {
        return { ...state, qty: value };
      }
      return { ...state, qty: null };
    case SET_CONTAINER_SCRAP:
      if (value) {
        return { ...state, scrap: value };
      }
      return { ...state, scrap: null };
    case SET_CONTAINER_NOTES:
      return { ...state, notes: value };
    case SET_CONTAINER_BATCH:
      if (value) {
        return { ...state, batch: value };
      }
      return { ...state, batch: null };
    case SET_CONTAINER_SUPPLIER:
      if (value) {
        return { ...state, supplier: value };
      }
      return { ...state, supplier: null };
    case SET_CONTAINER_SERIALNUMBER_CODE:
      if (value) {
        return { ...state, serialNumberCode: value };
      }
      return { ...state, serialNumberCode: null };
    case SET_CONTAINER_ITEM:
      if (value) {
        return { ...state, item: value };
      }
      return { ...state, item: null };
    default:
      return state;
  }
};

export default reducer;
