import { useMutation, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { loader } from "graphql.macro";
import styled from "styled-components";

import {
  setContainerBatch,
  setContainerItem,
  setContainerName,
  setContainerNotes,
  setContainerQty,
  setContainerScrap,
  setContainerSerialnumberCode,
  setContainerSupplier,
} from "../../../../actions";
import NumberField from "../../../../components/Fields/NumberField";
import SearchSelectField from "../../../../components/Fields/SearchSelectField";
import TextField from "../../../../components/Fields/TextField";
import TextareaField from "../../../../components/Fields/TextareaField";
import HmiFieldRoute from "../../../../components/HmiFieldRoute/HmiFieldRoute";
import LoadingIndicator from "../../../../components/LoadingIndicator";
import CenteredLayout from "../../../../layouts/CenteredLayout/CenteredLayout.layout";
import onError from "../../../../libs/error-logger";
import intl from "../../../../libs/format-message";
import toast from "../../../../libs/toast";
import GenericError from "../../../errors/GenericError";

const CONTAINER_SUPPLIERS = loader("./ContainerSuppliers.query.graphql");
const CONTAINER_LOAD = loader("../../_shared/ContainerLoad.mutation.graphql");
const CONTAINER_UPSERT = loader("./ContainerUpsert.mutation.graphql");
const BATCH_UPSERT = loader("../../_shared/UpsertBatch.mutation.graphql");
const GENERATE_SERIALNUMBERS = loader(
  "./GenerateSerialnumbers.mutation.graphql"
);

const Badge = styled.div`
  border-radius: 1.9rem;
  background-color: #eee;
  padding: 0.4rem 1.4rem 0.4rem 0.6rem;
  margin-top: 0.5rem;
  display: inline-block;
  margin-right: 0.4rem;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
  &.error {
    background-color: #f8d6e2;
    color: #d9355b;
  }
`;

function ContainerAdd() {
  const { type, sessionId, pickingId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { areaId, stationId } = useSelector(({ config }) => config);
  const { hmiConfiguration } = useSelector(({ config }) => config);
  const container = useSelector(({ container }) => container);
  const [step, setStep] = useState(0);
  const [stepIndex, setStepIndex] = useState(0);
  const [search, setSearch] = useState("");

  const variables = {
    options: { offset: 0, limit: 25 },
    search,
    pickingId,
    sessionId,
  };

  const { loading, error, data } = useQuery(CONTAINER_SUPPLIERS, { variables });

  const [mutateLoad] = useMutation(CONTAINER_LOAD, {
    variables: { sessionId },
  });
  const [mutateUpsertContainer] = useMutation(CONTAINER_UPSERT, {
    variables: { sessionId },
  });
  const [mutateUpsertBatch] = useMutation(BATCH_UPSERT, {
    variables: { sessionId },
  });

  const [mutateGenerateSerialnumbers] = useMutation(GENERATE_SERIALNUMBERS);

  if (loading && !data && search == "") {
    return (
      <CenteredLayout>
        <LoadingIndicator />
      </CenteredLayout>
    );
  }
  if (error) {
    return <GenericError error={error.message} />;
  }

  const { suppliers, nextSerialnumber, itemsPaged, session } = data ?? {
    suppliers: [],
    nextSerialnumber: [],
    itemsPaged: { items: [] },
  };

  const { pickingItem, phase } = session ?? {
    pickingItem: {},
    phase: {},
  };
  const { item } = pickingItem ?? {
    item: {},
  };
  const itemId = item?._id;
  const containerQty =
    Math.max(0, phase?.qty - phase?.produced) * pickingItem?.qty;
  const containerUnit = item?.unit;
  const items = itemsPaged?.items;

  async function onSubmit() {
    try {
      const variables = {
        container: {
          name: container.name,
          _station: stationId,
          unit: "Unit",
          _item: itemId,
          _area: areaId,
        },
      };

      const containerResult = await mutateUpsertContainer({ variables });
      const containerId = containerResult.data.container._id;

      if (!container.qty) {
        return history.goBack();
      }

      try {
        const variables = {
          batch: {
            code: container.batch,
            _supplier: container.supplier,
            qty: container.qty,
            _item: itemId,
          },
        };

        const batchResult = await mutateUpsertBatch({ variables });
        const batchId = batchResult.data.batch._id;
        let serialnumberIds;
        if (type == "serialnumber") {
          const firstSerialnumberCode =
            container.serialNumberCode ?? nextSerialnumber.code;
          const serialnumberResult = await mutateGenerateSerialnumbers({
            variables: {
              qty: Number(container.qty),
              batchId,
              firstCode: firstSerialnumberCode,
            },
          });
          if (!serialnumberResult.data.serialnumbers) {
            throw new Error(
              intl.formatMessage({
                id: "app.pages.stock.containers.container.actions.load.serialnumbers.error",
                defaultMessage: "Sorry, serialnumbers not created",
              })
            );
          }
          serialnumberIds = serialnumberResult.data.serialnumbers.map(
            (serialnumber) => serialnumber._id
          );
        }
        try {
          const variables = {
            containerId,
            qty: container.qty,
            serialnumberIds,
            batchId,
            scrap: container.scrap,
            notes: container.notes,
          };

          await mutateLoad({ variables });
          toast({
            title: intl.formatMessage({ id: "app.shared.save_success" }),
          });
          history.goBack();
        } catch (error) {
          onError(error);
        }
      } catch (error) {
        onError(error);
      }
    } catch (error) {
      onError(error);
    }
  }

  const subtitle = (
    <Badge>
      <i className="icon-product" /> {item?.code}
    </Badge>
  );

  // Build step sequence accordingly to HMI configuration
  const stepSequence = [0, 1];
  if (!hmiConfiguration.SKIP_CONTAINER_SCRAP) stepSequence.push(2);
  if (type === "serialnumber") stepSequence.push(3);
  stepSequence.push(4);
  if (hmiConfiguration.CHECK_CONTAINER_ARTICLE) stepSequence.push(5);
  if (!hmiConfiguration.SKIP_CONTAINER_SUPPLIER) stepSequence.push(6);
  if (!hmiConfiguration.SKIP_CONTAINER_NOTES) stepSequence.push(7);

  const incStep = (inc = 1) => {
    const newIndex =
      inc > 0
        ? Math.min(stepIndex + 1, stepSequence.length - 1)
        : Math.max(0, stepIndex - 1);
    setStepIndex(newIndex);
    setStep(stepSequence[newIndex]);
  };

  return (
    <>
      {/* NAME */}
      {step === 0 && (
        <HmiFieldRoute
          title={intl.formatMessage({
            id: "app.pages.application.container_name.title",
          })}
          {...(stepIndex === stepSequence.length - 1
            ? { onSubmit: onSubmit }
            : {
                onNext: () => {
                  // Check if container name is not empty and is the same of item name
                  if (hmiConfiguration.CHECK_CONTAINER_ITEM_NAME) {
                    if (
                      container.name &&
                      container.name != item.name &&
                      item.name != ""
                    ) {
                      onError(
                        Error(
                          intl.formatMessage({
                            id: "app.pages.application.container_name.error",
                            defaultMessage: "Sorry, wrong article name",
                          })
                        )
                      );
                    } else {
                      incStep();
                    }
                  } else {
                    incStep();
                  }
                },
              })}
          onBack={() => {
            dispatch(setContainerName());
            history.goBack();
          }}
          subtitle2={subtitle}
          field={
            <TextField
              key="name"
              autofocus={true}
              onChange={(value) => {
                dispatch(setContainerName(value));
              }}
            />
          }
        />
      )}
      {/* QUANTITY */}
      {step === 1 && (
        <HmiFieldRoute
          title={intl.formatMessage({
            id: "app.pages.application.produced.title",
          })}
          {...(stepIndex === stepSequence.length - 1
            ? { onSubmit: onSubmit }
            : { onNext: () => incStep() })}
          defaultAction={() => {
            // Populate value on NumberField key=qty with containerQty
            document.querySelector("input[name=text-field]").value =
              containerQty;
            dispatch(setContainerQty(containerQty));
          }}
          value={containerQty}
          unit={containerUnit}
          onBack={() => {
            dispatch(setContainerQty());
            incStep(-1);
          }}
          subtitle2={subtitle}
          field={
            <NumberField
              key="qty"
              autofocus={true}
              placeholder={0}
              defaultValue={0}
              onChange={(value) => {
                dispatch(setContainerQty(value));
              }}
            />
          }
        />
      )}
      {/* SCRAP */}
      {step === 2 && (
        <HmiFieldRoute
          title={intl.formatMessage({
            id: "app.pages.application.scrap.title",
          })}
          {...(stepIndex === stepSequence.length - 1
            ? { onSubmit: onSubmit }
            : { onNext: () => incStep() })}
          onBack={() => {
            dispatch(setContainerScrap());
            incStep(-1);
          }}
          subtitle2={subtitle}
          field={
            <NumberField
              key="scrap"
              autofocus={true}
              placeholder={container.scrap}
              defaultValue={container.scrap}
              onChange={(value) => {
                dispatch(setContainerScrap(value));
              }}
            />
          }
        />
      )}
      {/* FIRST-SERIALNUMBER */}
      {step === 3 && (
        <HmiFieldRoute
          title={intl.formatMessage({
            id: "app.pages.application.container_serialnumber.title",
            defaultMessage: "First serialnumber code",
          })}
          {...(stepIndex === stepSequence.length - 1
            ? { onSubmit: onSubmit }
            : { onNext: () => incStep() })}
          onBack={() => {
            dispatch(setContainerSerialnumberCode());
            incStep(-1);
          }}
          subtitle2={subtitle}
          field={
            <TextField
              key="batch"
              autofocus={true}
              placeholder={nextSerialnumber.code}
              onChange={(value) => {
                dispatch(setContainerSerialnumberCode(value));
              }}
            />
          }
        />
      )}
      {/* BATCH */}
      {step === 4 && (
        <HmiFieldRoute
          title={intl.formatMessage({
            id: "app.pages.application.container_batch.title",
          })}
          {...(stepIndex === stepSequence.length - 1
            ? { onSubmit: onSubmit }
            : { onNext: () => incStep() })}
          onBack={() => {
            dispatch(setContainerBatch());
            incStep(-1);
          }}
          subtitle2={subtitle}
          field={
            <TextField
              key="batch"
              autofocus={true}
              onChange={(value) => {
                dispatch(setContainerBatch(value));
              }}
            />
          }
        />
      )}
      {/* BATCH-ITEM */}
      {step === 5 && (
        <HmiFieldRoute
          title={intl.formatMessage({
            id: "app.pages.application.container_batch.batch.article",
          })}
          {...(stepIndex === stepSequence.length - 1
            ? { onSubmit: onSubmit }
            : {
                onNext: () => {
                  if (
                    container.item &&
                    container.item != itemId &&
                    itemId != ""
                  ) {
                    onError(
                      Error(
                        intl.formatMessage({
                          id: "app.pages.stock.containers.container.actions.load.serialnumbers.error",
                          defaultMessage: "Sorry, wrong articleId selected",
                        })
                      )
                    );
                  } else {
                    incStep();
                  }
                },
              })}
          onBack={() => {
            dispatch(setContainerItem());
            incStep(-1);
          }}
          subtitle2={subtitle}
          field={
            <SearchSelectField
              key="item"
              autofocus={true}
              placeholder={container.item}
              defaultValue={container.item}
              options={items.map((i) => {
                return {
                  value: i._id,
                  title: i.code + "::" + i.name,
                  image: i.image ?? "",
                  subtitle: i.description,
                };
              })}
              search={search}
              onSearch={setSearch}
              onChange={(value) => {
                dispatch(setContainerItem(value));
              }}
            />
          }
        />
      )}
      {/* SUPPLIER */}
      {step === 6 && (
        <HmiFieldRoute
          title={intl.formatMessage({
            id: "app.pages.application.container_supplier.title",
          })}
          {...(stepIndex === stepSequence.length - 1
            ? { onSubmit: onSubmit }
            : { onNext: () => incStep() })}
          onBack={() => {
            dispatch(setContainerSupplier());
            incStep(-1);
          }}
          subtitle2={subtitle}
          field={
            <SearchSelectField
              key="supplier"
              placeholder={container.supplier}
              defaultValue={container.supplier}
              onChange={(value) => {
                dispatch(setContainerSupplier(value));
              }}
              options={suppliers.map((s) => {
                return { value: s._id, title: s.name };
              })}
            />
          }
        />
      )}
      {/* NOTES */}
      {step === 7 && (
        <HmiFieldRoute
          title={intl.formatMessage({
            id: "app.pages.application.notes.title",
          })}
          onSubmit={onSubmit}
          onBack={() => {
            dispatch(setContainerNotes());
            incStep(-1);
          }}
          subtitle2={subtitle}
          field={
            <TextareaField
              key="notes"
              autofocus={true}
              placeholder={container.notes}
              defaultValue={container.notes}
              onChange={(value) => {
                dispatch(setContainerNotes(value));
              }}
            />
          }
        />
      )}
    </>
  );
}

ContainerAdd.propTypes = {};
export default ContainerAdd;
