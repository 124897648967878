import swal from "sweetalert2";
import intl from "./format-message";

/**
 * Confirm an action
 *
 * @param confirm {function} confirm callback
 */
function checkError({ errors, ...others }) {
  if (errors) {
    return swal(
      intl.formatMessage({ id: "app.shared.there_was_an_error" }),
      JSON.stringify(errors),
      "error"
    );
  }
  return others;
}

function checkErrorMessage(message) {
  return swal.fire({
    title: intl.formatMessage({ id: "app.shared.there_was_an_error" }),
    text: message,
    icon: "error",
  });
}

export default checkError;
export { checkError, checkErrorMessage };
