import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useSelector } from "react-redux";
import Avatar from "../../../../../components/Avatar";
import { buttons } from "../../../../../libs/themes";

const Container = styled.div`
  position: relative;
  .image {
    margin-bottom: 0.8rem;
    width: 18rem;
  }
  .selected {
    position: absolute;
    top: -1.75rem;
    right: 0.15rem;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    border: 0.3rem solid rgba(245, 245, 245, 0.5);
    transition: background-color 250ms ease-in-out,
      border-color 250ms ease-in-out 250ms;
    color: ${buttons.checkGreen.fg};
    background-color: ${buttons.checkGreen.bg};
  }
`;

function Program({ program, onClick }) {
  const { programId } = useSelector(({ config }) => config);
  const isSelected = programId === program._id;
  return (
    <Container className="column is-3 has-text-centered">
      <figure className="image" style={{ marginBottom: 8 }}>
        <a
          onClick={onClick}
          data-cy="configuration-advanced-programs-program"
          data-cy-id={program._id}
        >
          <Avatar size="12.8rem" name={program.name} src={program.image} />
        </a>
        {isSelected && <span className="selected" />}
      </figure>
      <h4 className="title is-4 w500 has-text-centered">{program.name}</h4>
      <p className="subtitle is-5 fg-secondary has-text-centered">
        {program.description || "-"}
      </p>
    </Container>
  );
}

Program.propTypes = {
  onClick: PropTypes.func.isRequired,
  program: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
  }).isRequired,
};
export default Program;
