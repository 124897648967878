function serialnumbersRange({ serialnumbers }) {
  // Get the first and last serialnumber codes to display
  const serialnumbersCodes = serialnumbers?.map(
    (serialnumber) => serialnumber.code
  );
  const firstSerialnumber = serialnumbersCodes?.[0];
  const lastSerialnumber = serialnumbersCodes?.[serialnumbersCodes.length - 1];

  const serialnumbersRange =
    serialnumbersCodes?.length > 0
      ? ` ${firstSerialnumber}${
          serialnumbersCodes.length > 1 &&
          lastSerialnumber !== firstSerialnumber
            ? " ... " + lastSerialnumber
            : ""
        }`
      : "";

  return serialnumbersRange;
}

function serialnumbersCount({ serialnumbers }) {
  return serialnumbers?.length || 0;
}

export { serialnumbersRange, serialnumbersCount };
