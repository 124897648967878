import { useMutation, useQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import Button from "../../../../components/Button";
import Header from "../../../../components/Header";
import Background from "../../../../components/Layout/Background";
import LoadingIndicator from "../../../../components/LoadingIndicator";
import Title from "../../../../components/Title";
import CenteredLayout from "../../../../layouts/CenteredLayout/CenteredLayout.layout";
import onError from "../../../../libs/error-logger";
import themes from "../../../../libs/themes";
import toast from "../../../../libs/toast";
import AlertThreshold from "./components/AlertThreshold";
import PickingItem from "./components/PickingItem";

const SUPPLY_SETTINGS = loader("./SupplySettings.query.graphql");
const SUPPLY_MUTATION = loader("./SupplySettings.mutation.graphql");

function SupplySettings() {
  const intl = useIntl();
  const [qty, setQty] = useState();
  const { sessionId } = useParams();
  const history = useHistory();
  const variables = { sessionId };
  const { data, loading, error } = useQuery(SUPPLY_SETTINGS, { variables });
  const [mutate] = useMutation(SUPPLY_MUTATION, { variables });
  const theme = themes.materialViolet;
  if (loading && !data) {
    return (
      <CenteredLayout theme={theme}>
        <LoadingIndicator />
      </CenteredLayout>
    );
  }
  if (error) {
    return `Error! ${error.message}`;
  }
  const { session } = data;
  const { picking } = session;

  async function onSubmit() {
    const value = qty ?? session.alertThreshold;
    //debugger;
    if (isNaN(value)) {
      toast({
        title: intl.formatMessage({ id: "app.shared.nan" }),
        icon: "error",
      });
      return;
    }
    try {
      const variables = { alertThreshold: value };
      await mutate({ variables });
      toast({ title: intl.formatMessage({ id: "app.shared.save_success" }) });
    } catch (error) {
      onError(error);
    }
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <Background
          head={<Header hasPhaseInfo />}
          foot={
            <div className="columns">
              <div className="column is-1">
                <Button
                  isFullWidth
                  theme={themes.default.buttons}
                  onClick={() => history.goBack()}
                  data-cy="application-button-back"
                >
                  <img
                    src={
                      require("../../../../images/arrow-back-gray-icn.svg")
                        .default
                    }
                    alt=""
                  />
                </Button>
              </div>
              <div className="column is-4 is-offset-7">
                <Button
                  isFullWidth
                  theme={themes.default.buttons}
                  data-cy="application-button-save"
                  onClick={onSubmit}
                >
                  <FormattedMessage
                    id="app.shared.save"
                    defaultMessage="Save"
                  />
                </Button>
              </div>
            </div>
          }
        >
          <>
            <div className="columns">
              <div className="column is-full has-text-centered">
                <Title.H1>
                  <FormattedMessage
                    id="app.pages.application.supply_settings.title"
                    defaultMessage="Supply alerts settings"
                  />
                </Title.H1>
              </div>
            </div>
            <div className="columns">
              {/* PICKING LIST */}
              <div className="column is-half">
                <Title.H3>
                  <FormattedMessage
                    id="app.pages.application.supply_settings.picking_list"
                    defaultMessage="Picking list"
                  />
                </Title.H3>
                <div className="separator" style={{ marginBottom: "1rem" }} />
                {picking.map((pickingItem) => (
                  <PickingItem key={pickingItem._id} picking={pickingItem} />
                ))}
              </div>
              {/* NOTICE */}
              <div className="column is-half">
                <Title.H3>
                  <FormattedMessage
                    id="app.pages.application.supply_settings.threshold"
                    defaultMessage="Alert threshold (units)"
                  />
                </Title.H3>
                <div className="separator" />
                <AlertThreshold
                  qty={session.alertThreshold || qty || 0}
                  setQty={setQty}
                />
              </div>
            </div>
          </>
        </Background>
      </ThemeProvider>
    </>
  );
}

SupplySettings.propTypes = {};
export default SupplySettings;
