import React, { useEffect, useRef } from "react";

import { ThemeProvider } from "styled-components";
import themes from "../../libs/themes";
import Header from "../Header";
import HmiField from "../HmiField/HmiField";
import Background from "../Layout/Background";

function HmiFieldRoute(props) {
  const inputEl = useRef(null);

  useEffect(() => {
    inputEl && inputEl.current && inputEl.current.select();
  }, [inputEl]);
  const theme = themes.neutralGrey;

  return (
    <>
      <ThemeProvider theme={theme}>
        <Background head={<Header hasPhaseInfo />}>
          <HmiField {...props} />
        </Background>
      </ThemeProvider>
    </>
  );
}

export default HmiFieldRoute;
