import PropTypes from "prop-types";
import React from "react";
import { useHistory, useParams } from "react-router-dom";

import styled from "styled-components";
import Avatar from "../../../../../components/Avatar";
import NumberFormatter from "../../../../../libs/format-number";

const StyledWrapper = styled.div`
  .image {
    margin-bottom: 0.8rem;
  }

  .image,
  a {
    width: 12.8rem;
    height: 12.8rem;
  }
`;

function PickingItem({ picking }) {
  const history = useHistory();
  const { phaseId, sessionId, stepId } = useParams();
  const { container } = picking;
  return (
    <StyledWrapper className="column is-3 has-text-centered">
      <figure className="image is-relative">
        <a
          data-cy="application-supply-picking"
          data-cy-id={picking._id}
          onClick={() =>
            history.push(
              `/application/supply-item/${phaseId}/${sessionId}/${picking._id}/${stepId}`
            )
          }
        >
          <Avatar
            size="12.8rem"
            name={picking.item.code}
            src={picking.item.image}
          />
        </a>
      </figure>
      <h4 className="title is-4 w500 has-text-centered">
        {picking.item.code} :: <small>{picking?.item?.name}</small>
      </h4>
      {container && (
        <p className="subtitle is-4 fg-secondary has-text-centered">
          {container?.name}{" "}
          {container?.batch && <small>({container.batch.code})</small>}
          {" - "}
          <strong>
            <NumberFormatter value={picking.remaining} /> {container.unit}
          </strong>
          {container.capacity && (
            <>
              <small>
                /<NumberFormatter value={container.capacity} />
              </small>{" "}
              <small className="fg-bluish-grey">
                {container.percentage || 0}%
              </small>
            </>
          )}
        </p>
      )}
    </StyledWrapper>
  );
}

PickingItem.propTypes = {
  picking: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    qty: PropTypes.number.isRequired,
    remaining: PropTypes.number,
    container: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      batch: PropTypes.object.isRequired,
      capacity: PropTypes.number,
      current: PropTypes.number,
      percentage: PropTypes.number,
    }),
    item: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
      image: PropTypes.string,
    }).isRequired,
  }).isRequired,
};
export default PickingItem;
