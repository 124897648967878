import { useQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import { default as React } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";

import Button from "../../../../components/Button";
import ContainerActions from "../../../../components/ContainerActions";
import Header from "../../../../components/Header";
import Background from "../../../../components/Layout/Background";
import LoadingIndicator from "../../../../components/LoadingIndicator";
import ProgressBar from "../../../../components/ProgressBar";
import Subtitle from "../../../../components/Subtitle";
import { SmallTag } from "../../../../components/Tag";
import Title from "../../../../components/Title";
import CenteredLayout from "../../../../layouts/CenteredLayout/CenteredLayout.layout";
import NumberFormatter from "../../../../libs/format-number";
import themes from "../../../../libs/themes";

const CONTAINER_CONTENT = loader("./ContainerContent.query.graphql");

const Badge = styled.div`
  border-radius: 1.9rem;
  background-color: #eee;
  padding: 0.4rem 1.4rem 0.4rem 0.6rem;
  margin-top: 0.5rem;
  display: inline-block;
  margin-right: 0.4rem;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
  &.error {
    background-color: #f8d6e2;
    color: #d9355b;
  }
`;

const ContainerRect = styled.div`
  background-color: #f5f5f5;
  border-radius: 0.8rem;
  overflow: hidden;
  margin-top: -0.75rem;
  .columns {
    padding: 0;
    > .column {
      padding: 2.4rem 2.4rem 1.8rem 2.4rem;
    }
  }
  .column.right {
    background-color: #f2f2f2;
  }
`;

const Smiro = styled.div`
  width: 100%;
  height: calc((33vw - 9rem) / 1.618);
  border-radius: 0.8rem;
  background-color: #fff;
  padding: 2.4rem;
  position: relative;
  overflow: hidden;
  &:hover {
    /*background-color: #ccc;*/
  }
  .light {
    position: absolute;
    z-index: 2;
    top: 50%;
    border-radius: 50%;
    transform: translateY(-50%) translateX(-50%);
    left: 18%;
    background-color: whitesmoke;
    width: 42px;
    height: 42px;
    &:after {
      opacity: 0;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      content: "";
      height: 100%;
      width: 100%;
      background: rgba(255, 0, 0, 0.33);
      border-radius: 100%;
      animation-name: ripple;
      animation-duration: $duration;
      animation-delay: $delay;
      animation-iteration-count: infinite;
      animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
      z-index: 2;
    }

    @keyframes ripple {
      from {
        opacity: 1;
        transform: scale3d(0.75, 0.75, 1);
      }

      to {
        opacity: 0;
        transform: scale3d(2, 2, 1);
      }
    }
  }
  .sign {
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    left: 18%;
    background-color: whitesmoke;
    width: 12px;
    height: 38%;
    &.sign-2 {
      width: 22%;
      height: 12px;
      left: 18%;
    }
  }

  .barcode-container {
    float: right;
    width: calc(100% / 2.1);
    padding: 1.6rem 4.8rem 3.2rem 1.6rem;
    background-color: #b7c4b8;
    height: calc((33vw - 4rem) / 1.618 / 3.2);
    position: absolute;
    bottom: 0;
    right: 0;
    border-radius: 0.4rem 0 0 0;
    min-height: 8rem;

    .barcode {
      width: 100%;
      height: 100%;
      border-radius: 0.8rem;
      background-color: #fff;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: start;
      padding: 0.8rem 0.4rem;
      div {
        background-color: rgba(0, 0, 0, 0.6);
        width: 0.4rem;
        height: 100%;
      }
      div:nth-child(1n) {
        width: 0.2rem;
        margin-left: 0.1rem;
      }
      div:nth-child(2n) {
        width: 0.05rem;
      }
      div:nth-child(3n) {
        margin-left: 0.3rem;
        width: 0.4rem;
      }
      div:nth-child(4n) {
        margin-left: 0.2rem;
        width: 0.3rem;
      }
    }
  }
`;

const ButtonColumns = styled.div`
  margin-top: 1.2rem;
`;

const SmiroColumn = styled.div`
  padding-top: 2.8rem !important;
  padding-bottom: 3.4rem !important;
  padding-left: 2.8rem !important;
  padding-right: 3.2rem !important;
  border-left: 1px solid #70707015;
  border-left: 1px solid #70707015;
  .columns {
    .column {
      &:nth-child(2) {
        padding-right: 0 !important;
        padding-left: 0 !important;
      }
      &:first-child {
        padding-left: 0 !important;
        padding-right: 1.8rem !important;
      }
      &:last-child {
        padding-right: 0 !important;
        padding-left: 1.8rem !important;
      }
    }
  }
`;

const ContainerHeader = styled.div`
  border-bottom: 1px solid #70707009;
  font-weight: 500;
  color: #969696;
  font-size: 1.6rem;
  line-height: 1.4rem;
  padding-top: 1.4rem;
  padding-bottom: 1.2rem;
  margin-bottom: 0.8rem;
`;

const UppercaseLabel = styled.div`
  font-weight: 500;
  color: #969696;
  font-size: 1.6rem;
  line-height: 1.4rem;
`;

function ContainerContent() {
  const { containerId, sessionId } = useParams();
  const history = useHistory();
  const variables = { containerId };
  const { data, loading, error } = useQuery(CONTAINER_CONTENT, { variables });
  if (loading && !data) {
    return (
      <CenteredLayout>
        <LoadingIndicator />
      </CenteredLayout>
    );
  }
  if (error) {
    return `Error! ${error.message}`;
  }
  const theme = themes.neutralGrey;
  const { container } = data;

  return (
    <>
      <ThemeProvider theme={theme}>
        <Background
          head={<Header hasPhaseInfo />}
          foot={
            <div className="columns">
              <div className="column is-1">
                <Button
                  isFullWidth
                  theme={themes.default.buttons}
                  onClick={() => history.goBack()}
                  data-cy="application-button-back"
                >
                  <img
                    src={
                      require("../../../../images/arrow-back-gray-icn.svg")
                        .default
                    }
                    alt=""
                  />
                </Button>
              </div>
              {/* <div className="column is-4 is-offset-3">
                <Button
                  isFullWidth
                  isSmall={true}
                  theme={themes.primary.buttons}
                  onClick={() =>
                    containerId &&
                    history.push(`/application/container-item/${containerId}`)
                  }
                >
                  <FormattedMessage
                    id="app.shared.container_content.select_item"
                    defaultMessage="Select item"
                  />
                </Button>
              </div> */}
            </div>
          }
        >
          <>
            <div className="columns is-fullwidth">
              <div className="column">
                <SmallTag isUpperCase isLight theme={themes.secondary.buttons}>
                  <FormattedMessage
                    id="app.pages.application.supply_item.container.title"
                    defaultMessage="Container"
                  />
                </SmallTag>
                <Title.H1>{container?.name ? container.name : "-"}</Title.H1>
                <Subtitle.S4 theme={themes.secondary}>
                  {container.batch && (
                    <Badge>
                      <i className="icon-doc-text" />
                      {container.batch ? container.batch.code : "-"}
                    </Badge>
                  )}
                  {container.item?.code && (
                    <Badge>
                      <i className="icon-product" />
                      {container.item.code}
                    </Badge>
                  )}
                </Subtitle.S4>
              </div>
            </div>
            <ContainerRect>
              <div className="columns">
                <div className="column has-text-left">
                  <div
                    className="columns"
                    style={{ borderBottom: "0.3px solid #efefef" }}
                  >
                    <div className="column is-paddingless-top">
                      {/*<div className="subtitle is-5">
                        <ContainerMovementType type={type} size="24rem" />
                     </div>*/}
                      <ContainerHeader>Contenuto</ContainerHeader>
                      <h4 className="title is-4 has-text-weight-normal ">
                        <div
                          className={
                            "title is-3 has-text-weight-bold " +
                            (container.item || "fg-secondary")
                          }
                        >
                          {container.item
                            ? container.item?.name
                            : "Nessun contenuto"}
                        </div>
                      </h4>
                      <hr />
                      <UppercaseLabel>Capienza</UppercaseLabel>
                      <h1
                        className="title is-1"
                        data-cy="application-container-qty"
                      >
                        <NumberFormatter value={container.current} />{" "}
                        <small className="subtitle is-1">/</small>{" "}
                        <NumberFormatter value={container.capacity} />{" "}
                        <span className="title is-4">{container.unit}</span>
                      </h1>
                      <ProgressBar
                        title="Unità prodotte"
                        completed={container?.capacity}
                        total={container.remaining}
                        hasTitle={false}
                        theme={themes.productionBlue}
                      />
                      <ButtonColumns className="columns is-paddingless-left has-40-padding-top">
                        <div className="column is-paddingless-left is-paddingless-right">
                          <Button
                            isFullWidth
                            disabled={!container}
                            onClick={() =>
                              container &&
                              history.push(
                                `/application/container-load/${container?._id}`
                              )
                            }
                            data-cy="application-supply_item-container-load"
                            theme={themes.default}
                          >
                            <p className="title is-3">
                              <i className="icon-download-icn" />
                              <FormattedMessage
                                id="app.pages.application.container_content.load"
                                defaultMessage="Manage container"
                              />
                            </p>
                          </Button>
                        </div>
                        <div className="column is-paddingless-right">
                          <Button
                            isFullWidth
                            disabled={!container}
                            onClick={() =>
                              container &&
                              history.push(
                                `/application/container-edit/${container?._id}/${sessionId}`
                              )
                            }
                            data-cy="application-supply_item-container-edit"
                            theme={themes.default}
                          >
                            <p className="title is-3">
                              <i className="icon-giphy-edit" />
                              <FormattedMessage
                                id="app.pages.configuration.operations.edit"
                                defaultMessage="Edit"
                              />
                            </p>
                          </Button>
                        </div>
                      </ButtonColumns>
                    </div>
                  </div>
                </div>
                <SmiroColumn
                  className="column is-4 right has-20-padding-left has-30-padding-right"
                  style={{
                    minWidth: "40rem",
                  }}
                >
                  {container?.type != "" && (
                    <>
                      <ContainerActions
                        container={container}
                        isPopup={true}
                        isLarge={true}
                      />
                      <Smiro style={{ marginTop: "1.2rem" }}>
                        <div className="sign"></div>
                        <div className="sign sign-2"></div>
                        <div className="light ripple"></div>
                        <div className="barcode-container">
                          <div className="barcode">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        </div>
                      </Smiro>
                    </>
                  )}
                </SmiroColumn>
              </div>
            </ContainerRect>
          </>
        </Background>
      </ThemeProvider>
    </>
  );
}

ContainerContent.propTypes = {};
export default ContainerContent;
