import React from "react";
import PropTypes from "prop-types";
import Iframe from "react-iframe";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { useSelector } from "react-redux";

import Modal from "../../Modal";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  iframe {
    height: calc(100vh - 14rem);
  }
`;

/**
 * The single document component, it displays standards documents (MATERIAL, SETUP, etc) not BOM!!!
 */
function Attachment({ doc }) {
  const { hmiConfiguration } = useSelector(({ config }) => config);
  const { INTERNAL_PDF, DOWNLOAD_ATTACHMENT } = hmiConfiguration;
  return (
    <Modal
      type={doc.type}
      head={
        DOWNLOAD_ATTACHMENT ? (
          <p className="title is-4 w500 has-text-centered has-text-white">
            <Link to={{ pathname: doc.link }} target="_blank" download>
              <i className="icon-download-icn" />{" "}
              <FormattedMessage
                id={`app.components.docs.doc_default.doc.${doc.type}`}
                defaultMessage={doc.type}
              />
            </Link>
          </p>
        ) : (
          <p className="title is-4 w500 has-text-centered has-text-white">
            <FormattedMessage
              id={`app.components.docs.doc_default.doc.${doc.type}`}
              defaultMessage={doc.type}
            />
          </p>
        )
      }
      button={
        <>
          <img
            src={
              doc.type ? require(`../../../images/doc/${doc.type}.svg`) : null
            }
            alt=""
          />
          <p>
            <FormattedMessage
              id={`app.components.docs.doc_default.doc.${doc.type}`}
              defaultMessage={doc.type}
            />
          </p>
        </>
      }
    >
      <Choose>
        <When condition={!INTERNAL_PDF && doc.mimeType === "application/pdf"}>
          <Wrapper className="image">
            <Iframe
              url={`/ViewerJS/index.html#${doc.link}`}
              width="100%"
              height="100%"
            />
          </Wrapper>
        </When>
        <When condition={INTERNAL_PDF && doc.mimeType === "application/pdf"}>
          <object
            data={doc.link}
            type={doc.mimeType}
            className="image"
            style={{ width: "100%", height: "100%" }}
          >
            <embed src={doc.link} type={doc.mimeType} />
          </object>
        </When>
        <Otherwise>
          <img
            src={doc.link}
            alt=""
            className="image"
            style={{
              margin: "auto",
              width: "100%",
              height: "auto",
            }}
          />
        </Otherwise>
      </Choose>
    </Modal>
  );
}

Attachment.propTypes = {
  doc: PropTypes.shape({
    type: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    mimeType: PropTypes.string.isRequired,
  }).isRequired,
};
export default Attachment;
