import { useQuery } from "@apollo/client";
import { endOfDay, startOfDay } from "date-fns";
import { loader } from "graphql.macro";
import { parseInt } from "lodash";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import Button from "../../../components/Button";
import Header from "../../../components/Header";
import Background from "../../../components/Layout";
import LoadingIndicator from "../../../components/LoadingIndicator";
import Title from "../../../components/Title";
import BackImage from "../../../images/arrow-back-gray-icn.svg";
import CenteredLayout from "../../../layouts/CenteredLayout/CenteredLayout.layout";
import themes from "../../../libs/themes";
import Session from "./components/Session";

const TIMESHEET_SESSIONS = loader("./Timesheet.query.graphql");

function Timesheet() {
  const intl = useIntl();
  const theme = themes.default;
  const history = useHistory();
  const user = useSelector(({ user }) => user);
  const start = startOfDay(new Date());
  const end = endOfDay(new Date());
  const variables = {
    variables: {
      workerId: user.worker._id,
      start,
      end,
    },
  };

  const { data, loading, error } = useQuery(TIMESHEET_SESSIONS, variables);

  if (loading && !data) {
    return (
      <CenteredLayout>
        <LoadingIndicator />
      </CenteredLayout>
    );
  }

  if (error) {
    return `Error! ${error.message}`;
  }

  const sessions = data == null ? [] : data.sessions;
  const totalTime = data.worker.timers.PRODUCTION;

  const hourStr = intl.formatMessage({
    id: "app.components.unit.h",
    defaultMessage: "h",
  });
  const minuteStr = intl.formatMessage({
    id: "app.components.unit.m",
    defaultMessage: "m",
  });

  return (
    <>
      <ThemeProvider theme={theme}>
        <Background
          head={<Header hasUserInfo hasSessionInfo={false} />}
          foot={
            <div className="columns">
              <div className="column is-1">
                <Button
                  isFullWidth
                  onClick={() => history.push("/worker/dashboard")}
                >
                  <img src={BackImage} alt="" />
                </Button>
              </div>
              <div className="column is-2"></div>
              <div className="column"></div>
              <div
                className="column is-2 has-right-text"
                style={{ paddingTop: "1.8rem" }}
              >
                {
                  <>
                    <FormattedMessage
                      id="app.pages.configuration.operations.total"
                      defaultMessage="Totale"
                    />
                    <h1 className="title is-2 w700">
                      {parseInt((totalTime ?? 0) / 60 / 60) +
                        hourStr +
                        " " +
                        (
                          parseInt(
                            (totalTime ?? 0) / 60 -
                              parseInt((totalTime ?? 0) / 60 / 60) * 60
                          ) + ""
                        ).substring(0, 2) +
                        minuteStr}
                    </h1>
                  </>
                }
              </div>
            </div>
          }
        >
          <>
            {/* HEAD */}
            <div className="columns">
              <div className="column has-text-centered">
                <h1 className="title is-1 w700">
                  <FormattedMessage
                    id="app.pages.timesheet.button.timesheet"
                    defaultMessage="Timesheet"
                  />
                </h1>
                <p className="subtitle is-6 fg-secondary">
                  <FormattedMessage
                    id="app.pages.timesheet.button.operations.subtitle"
                    defaultMessage="Fill in the form with the times of your work sessions"
                  />
                  <br />
                </p>
              </div>
            </div>
            {/* DATA */}
            {
              <div className="columns">
                <div className="column is-12">
                  {sessions.map((session) => (
                    <Session key={session._id} session={session} />
                  ))}
                  {sessions.length === 0 && (
                    <Title.H3>
                      <FormattedMessage
                        id="app.pages.configuration.operations.no_phase"
                        defaultMessage="There is no phase, contact responsible"
                      />
                    </Title.H3>
                  )}
                </div>
              </div>
            }
          </>
        </Background>
      </ThemeProvider>
    </>
  );
}

export default Timesheet;
