import React, { useState } from "react";
import Pagination from "react-paginating";
import { useSelector } from "react-redux";

import Button from "../../../../../components/Button";
import AddContainer from "./AddContainer";
import Container from "./Container";

const PAGE_LIMIT = 8;

function ContainerList({ containers, onClick, session, onAdd, containerIds }) {
  const { hmiConfiguration } = useSelector(({ config }) => config);
  const [currentPage, setCurrentPage] = useState(1);

  if (hmiConfiguration.SHOW_ALL_CONTAINERS) {
    containers = [{ _id: "new" }, ...containers];
  }

  function paginate(containers, pageNumber) {
    const start = pageNumber - 1; // because pages logically start with 1, but technically with 0
    return containers.slice(start * PAGE_LIMIT, (start + 1) * PAGE_LIMIT);
  }
  const total = containers.length;
  const pageCount = Math.ceil(total / PAGE_LIMIT);

  return (
    <Pagination
      total={total}
      limit={PAGE_LIMIT}
      pageCount={pageCount}
      currentPage={currentPage}
    >
      {({
        pages,
        currentPage,
        hasNextPage,
        hasPreviousPage,
        previousPage,
        nextPage,
        getPageItemProps,
      }) => (
        <div className="columns is-vcentered">
          <div className="column is-narrow is-flex">
            {hasPreviousPage && (
              <Button
                isFullWidth
                {...getPageItemProps({
                  pageValue: previousPage,
                  onPageChange: setCurrentPage,
                })}
              >
                <i
                  className="icon-arrow-left-small-icn"
                  style={{ fontSize: "2.8rem" }}
                />
              </Button>
            )}
          </div>
          <div className="column">
            <div className="columns is-multiline">
              {paginate(containers, currentPage).map((container) =>
                container._id === "new" && onAdd ? (
                  <AddContainer
                    key={container._id}
                    onClick={() => {
                      onAdd();
                    }}
                    type={hmiConfiguration.SHOW_ALL_CONTAINERS ? "all" : "add"}
                  />
                ) : (
                  container._id !== "new" && (
                    <Container
                      key={container._id}
                      container={container}
                      containerIds={containerIds}
                      onClick={onClick}
                      session={session}
                    />
                  )
                )
              )}
            </div>
          </div>
          <div className="column is-narrow is-flex">
            {hasNextPage && (
              <Button
                isFullWidth
                {...getPageItemProps({
                  pageValue: nextPage,
                  onPageChange: setCurrentPage,
                })}
              >
                <i
                  className="icon-arrow-right-small-icn"
                  style={{ fontSize: "2.8rem" }}
                />
              </Button>
            )}
          </div>
        </div>
      )}
    </Pagination>
  );
}

export default ContainerList;
