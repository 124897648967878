import { useQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import Button from "../../../components/Button";
import Clock from "../../../components/Clock";
import Header from "../../../components/Header";
import Background from "../../../components/Layout";
import LoadingIndicator from "../../../components/LoadingIndicator";
import Subtitle from "../../../components/Subtitle";
import Title from "../../../components/Title";
import BackImage from "../../../images/arrow-back-gray-icn.svg";
import CenteredLayout from "../../../layouts/CenteredLayout/CenteredLayout.layout";
import themes from "../../../libs/themes";
import OperatorsGrouped from "./components/OperatorsGrouped";

const SESSIONS = loader("./Sessions.query.graphql");

/**
 * Sessions
 *
 * @returns {*}
 * @constructor
 */
function Sessions() {
  const theme = themes.default;
  const history = useHistory();

  const { phaseId } = useParams();
  const { hmiConfiguration } = useSelector(({ config }) => config);
  const { WORKERS_PAGE_SIZE } = hmiConfiguration;

  const { data, loading, error } = useQuery(SESSIONS, {
    variables: { phaseId },
  });
  if (loading && !data) {
    return (
      <CenteredLayout>
        <LoadingIndicator />
      </CenteredLayout>
    );
  }
  if (error) {
    return `Error! ${error.message}`;
  }

  const operators = data.phase.activeSessions.map((s) => s.worker);

  function onBack() {
    return history.goBack();
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <Background
          head={
            <Header hasSessionInfo={false} hasClock={false}>
              <div className="column has-text-centered">
                <Clock />
              </div>
            </Header>
          }
          foot={
            <div className="columns">
              <div className="column is-1">
                <Button isFullWidth theme={theme.buttons} onClick={onBack}>
                  <img src={BackImage} alt="" />
                </Button>
              </div>
            </div>
          }
        >
          <>
            <div className="columns">
              <div className="column is-full has-text-centered">
                <Title.H1>
                  <FormattedMessage
                    id="app.pages.application.sessions.title"
                    defaultMessage="Operators active this session"
                  />
                </Title.H1>
                <Subtitle.S3 theme={themes.secondary}>
                  {`${data.phase.sort} :: ${data.phase.name}`}
                </Subtitle.S3>
              </div>
            </div>

            <OperatorsGrouped
              operators={operators}
              pageSize={WORKERS_PAGE_SIZE}
            />
            {operators.length === 0 && (
              <Title.H2>
                <FormattedMessage
                  id="app.pages.configuration.operators.empty"
                  defaultMessage="Sorry, no operator assigned to this station, contact a responsible"
                />
              </Title.H2>
            )}
          </>
        </Background>
      </ThemeProvider>
    </>
  );
}

Sessions.propTypes = {};

export default Sessions;
