import React from "react";
import { FormattedMessage } from "react-intl";
import Loader from "./Loader";
/**
 * Shows a loader
 * @constructor
 */
function LoadingIndicator({ showIcon = true }) {
  return (
    <>
      <h2 className="title is-2">
        <FormattedMessage id="app.shared.loading" defaultMessage="Loading" />
      </h2>
      {showIcon && <Loader message="" isLarge={true} />}
    </>
  );
}

export default LoadingIndicator;
