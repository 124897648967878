import React from "react";
import styled from "styled-components";

const Serialnumber = styled.div`
  padding: 0.4rem 1.2rem;
  text-align: center;
  background-color: #ededed;
  display: inline-block;
  border-radius: 0.4rem;
  white-space: nowrap;
  overflow: hidden;
`;

function FirstLastSerialnumbers({ serialnumbers }) {
  if (!serialnumbers || serialnumbers.length === 0) {
    return <>-</>;
  }
  return (
    <h1 className="title is-4">
      <Serialnumber>
        <p>{serialnumbers[0].code}</p>
      </Serialnumber>
      {serialnumbers?.length > 1 &&
        serialnumbers[0].code !==
          serialnumbers[serialnumbers.length - 1].code && (
          <>
            {" ... "}
            <Serialnumber>
              <p>{serialnumbers[serialnumbers.length - 1].code}</p>
            </Serialnumber>
          </>
        )}
    </h1>
  );
}

export default FirstLastSerialnumbers;
