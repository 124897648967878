import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import Avatar from "../../../../../components/Avatar";
import { buttons } from "../../../../../libs/themes";
import { addMember, removeMember } from "../../../../../actions";

const Container = styled.div`
  position: relative;
  .image {
    margin-bottom: 0.8rem;
    width: 18rem;
  }
  .selected {
    position: absolute;
    top: -1.75rem;
    right: 0.15rem;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    border: 0.3rem solid rgba(245, 245, 245, 0.5);
    transition: background-color 250ms ease-in-out,
      border-color 250ms ease-in-out 250ms;
    color: ${buttons.checkGreen.fg};
    background-color: ${buttons.checkGreen.bg};
  }
`;

function Operator({ operator }) {
  const dispatch = useDispatch();
  const { team } = useSelector(({ config }) => config);
  const isSelected = team.some((member) => member === operator._id);

  function onClick(member, isSelected) {
    if (isSelected) {
      return dispatch(removeMember(member));
    }
    dispatch(addMember(member));
  }

  return (
    <Container className="column is-3 has-text-centered">
      <figure className="image">
        <a
          onClick={() => onClick(operator._id, isSelected)}
          data-cy="configuration-team-worker"
          data-cy-id={operator._id}
        >
          <Avatar size="12.8rem" name={operator.name} src={operator.image} />
        </a>
        {isSelected && <span className="selected" />}
      </figure>
      <h4 className="title is-4 w500 has-text-centered">{operator.name}</h4>
      <p className="subtitle is-5 fg-secondary has-text-centered">
        {operator.role}
      </p>
    </Container>
  );
}

Operator.propTypes = {
  onClick: PropTypes.func.isRequired,
  operator: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    role: PropTypes.string,
    image: PropTypes.string,
  }).isRequired,
};
export default Operator;
