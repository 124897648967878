import PropTypes from "prop-types";
import React from "react";
import { compose } from "redux";
import styled, { withTheme } from "styled-components";
import { FormattedMessage } from "react-intl";

import Button from "./Button";
import themes from "../libs/themes";

const SmiroContainer = styled.div`
  margin: auto;
  max-width: 40rem;
  &.upper {
    transform: translateY(-4rem);
  }
`;

const SmiroElem = styled.div`
  width: 100%;
  max-width: 40rem;
  height: calc((33vw - 9rem) / 1.618);
  border-radius: 0.8rem;
  background-color: #fff;
  padding: 2.4rem;
  position: relative;
  overflow: hidden;
  min-height: 24rem;
  margin: auto;
  margin-bottom: 2.4rem;
  &:hover {
    /*background-color: #ccc;*/
  }
  .light {
    position: absolute;
    z-index: 2;
    top: 50%;
    border-radius: 50%;
    transform: translateY(-50%) translateX(-50%);
    left: 18%;
    background-color: whitesmoke;
    width: 42px;
    height: 42px;
  }
  .sign {
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    left: 18%;
    background-color: whitesmoke;
    width: 12px;
    height: 38%;
    &.sign-2 {
      width: 22%;
      height: 12px;
      left: 18%;
    }
  }

  .barcode-container {
    float: right;
    width: calc(100% / 2.1);
    padding: 1.6rem 4.8rem 3.2rem 1.6rem;
    background-color: #b7c4b8;
    height: calc((33vw - 4rem) / 1.618 / 3.2);
    position: absolute;
    bottom: 0;
    right: 0;
    border-radius: 0.4rem 0 0 0;
    min-height: 8rem;

    .barcode {
      width: 100%;
      height: 100%;
      border-radius: 0.8rem;
      background-color: #fff;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: start;
      padding: 0.8rem 0.4rem;
      div {
        background-color: rgba(0, 0, 0, 0.6);
        width: 0.4rem;
        height: 100%;
      }
      div:nth-child(1n) {
        width: 0.2rem;
        margin-left: 0.1rem;
      }
      div:nth-child(2n) {
        width: 0.05rem;
      }
      div:nth-child(3n) {
        margin-left: 0.3rem;
        width: 0.4rem;
      }
      div:nth-child(4n) {
        margin-left: 0.2rem;
        width: 0.3rem;
      }
    }
  }
`;

function Smiro({
  children,
  selectable = false,
  selected = false,
  withCircle = true,
  onSelect,
  theme,
  centered,
  icon,
  index,
  scanRfid,
  ...props
}) {
  return (
    <SmiroContainer className={scanRfid != null ? "upper" : ""}>
      <SmiroElem>
        <div className="sign"></div>
        <div className="sign sign-2"></div>
        <div className="light"></div>
        <div className="barcode-container">
          <div className="barcode">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </SmiroElem>
      {scanRfid && (
        <Button
          isFullWidth
          isSmall={true}
          theme={themes.primary.buttons}
          onClick={scanRfid}
          style={{ marginBottom: "0.8rem" }}
        >
          <FormattedMessage
            id="app.shared.container_content.select_item"
            defaultMessage="Scan Tag"
          />
        </Button>
      )}
    </SmiroContainer>
  );
}

Smiro.propTypes = {
  children: PropTypes.node,
};
export default compose(withTheme)(Smiro);
