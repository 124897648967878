import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { Route, Router, Switch } from "react-router-dom";

import { setFeatures, setHmiConfiguration } from "../actions";
import config from "../config";
import history from "../history";
import HmiRoute from "../libs/hmi-route";

/** **************************
 *
 * Pages
 *
 *************************** */

// MISC
import Welcome from "./welcome";
// CONFIGURATION
import Advanced from "./configuration/advanced";
import ChooseOperation from "./configuration/choose-operation";
import ChooseOperator from "./configuration/choose-operator";
import ChooseOrder from "./configuration/choose-order";
import ChooseStation from "./configuration/choose-station";
import Definition from "./configuration/definition";
import NextPhase from "./configuration/next-phase";
import AddOperations from "./configuration/add-operations";

// APPLICATION
import * as Work from "./application/work";

import Delivery from "./application/delivery";
import End from "./application/end";
import ErrorPicker from "./application/error-picker";
import ErrorReport from "./application/error-report";
import Info from "./application/info";
import Machine from "./application/machine";
import Menu from "./application/menu";
import Notes from "./application/notes";
import Pause from "./application/pause";
import Picked from "./application/picked";
import Problem from "./application/problem";
import Produced from "./application/produced/Produced";
import ScanProduction from "./application/scan-production/ScanProduction";
import Scrap from "./application/scrap";
import Step from "./application/step";

import Containers from "./application/containers";
import containerChoose from "./application/containers/container-choose";
import ContainerBatch from "./application/containers/container-batch";
import ContainerContent from "./application/containers/container-content";
import ContainerLoad from "./application/containers/container-load";
import ContainerUnload from "./application/containers/container-unload";
import ContainerEmpty from "./application/containers/container-empty";
import ContainerItem from "./application/containers/container-item";
import ContainerQuantity from "./application/containers/container-quantity";
import ContainerRfid from "./application/containers/container-rfid/ContainerRfid";
import ContainerEdit from "./application/containers/container-edit/ContainerEdit";
import ContainerBatchSupplier from "./application/containers/container-batch-supplier/ContainerBatchSupplier";

import Supply from "./application/supply-alerts/supply";
import SupplyItem from "./application/supply-alerts/supply-item";
import SupplyItemBatch from "./application/supply-alerts/supply-item-batch";
import SupplyItemContainer from "./application/supply-alerts/supply-item-container";
import SupplyPicking from "./application/supply-alerts/supply-picking";
import SupplySettings from "./application/supply-alerts/supply-settings";

// BADGE
import Badge from "./badge";

// WORKER
import Dashboard from "./worker/dashboard";
import * as ManualSession from "./worker/manual-session";
import Timesheet from "./worker/timesheet";

// ERRORS
import ErrorIndicator from "../components/ErrorIndicator";
import LoadingIndicator from "../components/LoadingIndicator";
import CenteredLayout from "../layouts/CenteredLayout";
import NotConfigured from "./errors/NotConfigured";
import NotFound from "./errors/NotFound";
import Sessions from "./application/sessions/Sessions";
import ContainerAdd from "./application/containers/container-add/ContainerAdd";
import WithdrawNone from "./application/withdraw/withdraw-none/WithdrawNone";
import WithdrawContainer from "./application/withdraw/withdraw-container/WithdrawContainer";
import SupplyContainerChoose from "./application/supply-alerts/supply-container-choose/SupplyContainerChoose";
import WithdrawItem from "./application/withdraw/withdraw-item/WithdrawItem";

/**
 * Routes
 *
 * @returns {*}
 * @constructor
 */

function Routes() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    (async function getConfiguration() {
      const response = await fetch(`${config.configApi}/${window.HMI_ID}`);
      if (response.ok) {
        const { features, hmiConfiguration } = await response.json();
        dispatch(setHmiConfiguration(hmiConfiguration));
        dispatch(setFeatures(features));
      } else {
        setError(response.statusText);
      }
      setLoading(false);
    })();
  }, [setLoading, setError, dispatch]);
  if (loading) {
    return (
      <Router history={history}>
        <CenteredLayout>
          <LoadingIndicator />
        </CenteredLayout>
      </Router>
    );
  }
  if (error) {
    return (
      <Router history={history}>
        <CenteredLayout>
          <h2 className="title is-2">
            <FormattedMessage
              id="app.pages.errors.notconfigured"
              defaultMessage="Sorry, this HMI is not configured well, ask for support."
            />
          </h2>
          <p className="subtitle is-4">{error}</p>
        </CenteredLayout>
      </Router>
    );
  }
  if (error) {
    return (
      <Router history={history}>
        <CenteredLayout>
          <ErrorIndicator error={error} />
        </CenteredLayout>
      </Router>
    );
  }
  return (
    <Router history={history}>
      <Switch>
        <HmiRoute exact path="/" component={Welcome} />
        <HmiRoute path="/badge" component={Badge} />
        <HmiRoute path="/worker/dashboard" component={Dashboard} />
        <HmiRoute path="/worker/timesheet" component={Timesheet} />
        <HmiRoute
          path="/worker/manual-session/produced/:phaseId/:sessionId?"
          component={ManualSession.Produced}
        />
        <HmiRoute
          path="/worker/manual-session/time/:phaseId/:sessionId?"
          component={ManualSession.Time}
        />
        <HmiRoute
          path="/worker/manual-session/delivery/:phaseId/:sessionId?"
          component={ManualSession.Delivery}
        />
        <HmiRoute
          path="/worker/manual-session/notes/:phaseId/:sessionId?"
          component={ManualSession.Notes}
        />

        <HmiRoute path="/configuration/advanced" component={Advanced} />
        <HmiRoute path="/configuration/next-phase" component={NextPhase} />
        <HmiRoute
          path="/configuration/definition/:jobId/:phaseId"
          component={Definition}
        />
        <HmiRoute
          path="/configuration/add-operations"
          component={AddOperations}
        />
        <HmiRoute
          path="/configuration/operation/:jobId"
          component={ChooseOperation}
        />
        <HmiRoute path="/configuration/order" component={ChooseOrder} />
        <HmiRoute path="/configuration/station" component={ChooseStation} />
        <HmiRoute
          path="/configuration/operator/:index?"
          component={ChooseOperator}
        />
        <HmiRoute
          path="/application/menu/:phaseId/:sessionId"
          component={Menu}
        />
        <HmiRoute
          path="/application/definition/:jobId?/:phaseId?"
          component={Definition}
        />
        <HmiRoute
          path="/application/step/:phaseId/:sessionId/:stepId?"
          component={Step}
        />
        <HmiRoute
          path="/application/sessions/:phaseId/:sessionId?/:stepId?"
          component={Sessions}
        />
        <HmiRoute
          path="/application/container-add/:type/:phaseId?/:sessionId?/:pickingId?"
          component={ContainerAdd}
        />
        <HmiRoute
          path="/application/containers/container-choose/:type/:phaseId?/:sessionId?/:stepId?/:itemId?/:pickingId?"
          component={containerChoose}
        />
        <HmiRoute
          path="/application/containers/:phaseId?/:sessionId?/:stepId?"
          component={Containers}
        />
        <HmiRoute
          path="/application/container-content/:containerId/:sessionId"
          component={ContainerContent}
        />
        <HmiRoute
          path="/application/container-edit/:containerId/:sessionId"
          component={ContainerEdit}
        />
        <HmiRoute
          path="/application/container-batch/:containerId/:produced?"
          component={ContainerBatch}
        />
        <HmiRoute
          path="/application/container-batch-supplier/:containerId/:sessionId"
          component={ContainerBatchSupplier}
        />
        <HmiRoute
          path="/application/container-item/:containerId/:produced?"
          component={ContainerItem}
        />
        <HmiRoute
          path="/application/container-quantity/:containerId/:produced?"
          component={ContainerQuantity}
        />
        <HmiRoute
          path="/application/container-unload/:containerId/:produced?"
          component={ContainerUnload}
        />
        <HmiRoute
          path="/application/container-empty/:containerId/:produced?"
          component={ContainerEmpty}
        />
        <HmiRoute
          path="/application/container-load/:containerId/:produced?"
          component={ContainerLoad}
        />
        <HmiRoute
          path="/application/container-rfid/:containerId/:produced?"
          component={ContainerRfid}
        />
        <HmiRoute
          path="/application/withdraw-none/:step/:phaseId/:sessionId/:pickingId/:stepId?"
          component={WithdrawNone}
        />
        <HmiRoute
          path="/application/withdraw-item/:step/:phaseId/:sessionId/:stepId?"
          component={WithdrawItem}
        />
        <HmiRoute
          path="/application/withdraw-container/:step/:containerId/:phaseId/:sessionId/:pickingId/:stepId?"
          component={WithdrawContainer}
        />
        <HmiRoute
          path="/application/supply/:phaseId/:sessionId/:stepId?"
          component={Supply}
        />
        <HmiRoute
          path="/application/supply-picking/:phaseId/:sessionId/:stepId?"
          component={SupplyPicking}
        />
        <HmiRoute
          path="/application/supply-settings/:phaseId/:sessionId/:stepId?"
          component={SupplySettings}
        />
        <HmiRoute
          path="/application/supply-item/:phaseId/:sessionId/:pickingId/:stepId?"
          component={SupplyItem}
        />
        <HmiRoute
          path="/application/supply-item-container/:phaseId/:sessionId/:pickingId/:stepId?"
          component={SupplyItemContainer}
        />
        <HmiRoute
          path="/application/supply-item-batch/:phaseId/:sessionId/:pickingId/:stepId?"
          component={SupplyItemBatch}
        />
        <HmiRoute
          path="/application/supply-container-choose/:type/:phaseId?/:sessionId?/:stepId?/:itemId?/:pickingId?"
          component={SupplyContainerChoose}
        />
        <HmiRoute
          path="/application/error/picker/:phaseId/:sessionId/:stepId?"
          component={ErrorPicker}
        />
        <HmiRoute
          path="/application/error/report/:phaseId/:sessionId/:stepId?"
          component={ErrorReport}
        />
        <HmiRoute
          path="/application/picked/:phaseId/:sessionId/:stepId?"
          component={Picked}
        />
        <HmiRoute
          path="/application/machine/:phaseId/:sessionId/:machineId"
          component={Machine}
        />
        <HmiRoute
          path="/application/problem/:phaseId/:sessionId/:stepId?"
          component={Problem}
        />
        <HmiRoute
          path="/application/pause/:phaseId/:sessionId/:stepId?"
          component={Pause}
        />
        <HmiRoute
          path="/application/info/:phaseId/:sessionId/:stepId?"
          component={Info}
        />
        <HmiRoute
          path="/application/delivery/:phaseId/:sessionId/:stepId?"
          component={Delivery}
        />

        <HmiRoute
          path="/application/material/:phaseId/:sessionId"
          component={Work.Material}
        />

        <HmiRoute
          path="/application/setup/:phaseId/:sessionId"
          component={Work.Setup}
        />
        <HmiRoute
          path="/application/approval/:phaseId/:sessionId"
          component={Work.Approval}
        />
        <HmiRoute
          path="/application/check/:phaseId/:sessionId"
          component={Work.Check}
        />
        <HmiRoute
          path="/application/production/:phaseId/:sessionId"
          component={Work.Production}
        />
        <HmiRoute
          path="/application/scan-production/:phaseId/:sessionId"
          component={ScanProduction}
        />

        <HmiRoute
          path="/application/setup/:phaseId/:sessionId"
          component={Work.Setup}
        />
        <HmiRoute
          path="/application/approval/:phaseId/:sessionId"
          component={Work.Approval}
        />
        <HmiRoute
          path="/application/check/:phaseId/:sessionId"
          component={Work.Check}
        />
        <HmiRoute
          path="/application/production/:phaseId/:sessionId"
          component={Work.Production}
        />

        <HmiRoute
          path="/application/produced/:phaseId/:sessionId/:stepId?"
          component={Produced}
        />
        <HmiRoute
          path="/application/scrap/:phaseId/:sessionId/:stepId?"
          component={Scrap}
        />
        <HmiRoute
          path="/application/notes/:phaseId/:sessionId/:stepId?"
          component={Notes}
        />
        <HmiRoute path="/application/end/:phaseId" component={End} />

        <Route path="/errors/not-configured" component={NotConfigured} />
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
}

if (window.Cypress) {
  window.tgHistory = history;
}
export default Routes;
