import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { injectIntl } from "react-intl";
import styled from "styled-components";

import config from "../../../../../config";

const UPDATE_INTERVAL = 1000;

const Container = styled.div`
  .single-chart {
    .blinking {
      animation: blinker 1.5s linear infinite;
    }
  }
  line {
    stroke: rgb(255, 0, 0);
    stroke-width: 5;
  }
`;
class SessionTimer extends React.Component {
  static propTypes = {
    time: PropTypes.number,
    children: PropTypes.node,
  };

  static defaultProps = {
    time: 0,
  };

  state = {
    now: 0,
    componentMountedAt: moment(),
  };

  UNSAFE_componentWillMount() {
    this.startTimer();
  }

  componentWillUnmount() {
    this.stopTimer();
  }

  /**
   * Update mount time, to calculate new difference between NOW and MOUNTED_AT time
   * @param nextProps
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.time !== this.props.time) {
      this.setState({ componentMountedAt: moment() });
    }
  }

  startTimer() {
    this.loadInterval = setInterval(() => {
      this.forceUpdate();
    }, UPDATE_INTERVAL);
  }

  stopTimer() {
    this.loadInterval && clearInterval(this.loadInterval);
    this.loadInterval = false;
  }

  calculatePercentage(seconds) {
    return Math.round((+seconds * 100) / 60);
  }

  getHours(value) {
    const hours = Math.floor(value / 3600);
    if (hours < 10) {
      return `0${hours}`;
    }
    return hours || "00";
  }

  getMinutes(value) {
    const minutes = Math.floor((value % 3600) / 60);
    if (minutes < 10) {
      return `0${minutes}`;
    }
    return minutes || "00";
  }

  getSeconds(value) {
    const seconds = Math.floor(value % 60);
    if (seconds < 10) {
      return `0${seconds}`;
    }
    return seconds || "00";
  }
  componentDidMount() {
    //this.loadTimer();
  }
  // loadTimer() {
  //   var width = 800;
  //   var height = 800;
  //   var center = {
  //     x: width / 2,
  //     y: height / 2,
  //   };
  //   var radius = width / 2;
  //   var radius2 = radius - 100;
  //   var count = 44;
  //   var angle = 0;
  //   var slice = (Math.PI * 2) / count;
  //   var point = {
  //     x: 0,
  //     x2: 0,
  //     y: 0,
  //     y2: 0,
  //   };
  //   var i;
  //   var svg = d3
  //     .select(".lines")
  //     .append("svg")
  //     .attr("width", width)
  //     .attr("height", height)
  //     .append("g");
  //   for (i = 0; i < count; i += 1) {
  //     angle = i * slice;
  //     point.x = center.x + Math.cos(angle) * radius;
  //     point.x2 = center.x + Math.cos(angle) * radius2;
  //     point.y = center.y + Math.sin(angle) * radius;
  //     point.y2 = center.y + Math.sin(angle) * radius2;

  //     svg
  //       .append("line")
  //       .attr("x1", point.x)
  //       .attr("x2", point.x2)
  //       .attr("y1", point.y)
  //       .attr("y2", point.y2);
  //   }
  // }

  render() {
    const { intl } = this.props;
    const duration = moment.duration(
      moment().diff(moment(this.state.componentMountedAt))
    );
    const total = +duration.asSeconds() + this.props.total;
    const production = +duration.asSeconds() + this.props.production;

    const percentage = this.calculatePercentage(production % 60);

    return (
      <Container className="single-chart">
        <svg viewBox="0 0 36 36" className="circular-chart timing">
          <path
            className="circle-bg"
            d="M18 2.0845
              a 15.9155 15.9155 0 0 1 0 31.831
              a 15.9155 15.9155 0 0 1 0 -31.831"
          />
          <div id="lines"></div>
          {percentage > 0 && (
            <path
              className="circle"
              strokeDasharray={`${percentage || 0}, 100`}
              d="M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831"
            />
          )}

          <text x="18" y="15.2" className="circle-title">
            {intl.formatMessage({ id: "app.shared.production" })}
          </text>
          <text x="18" y="20" className="circle-value">
            {this.getHours(production)}:{this.getMinutes(production)}
            {config.showSeconds && <>:{this.getSeconds(production)}</>}
          </text>
          <line
            x1="10"
            x2="26"
            y1="21.5"
            y2="21.5"
            style={{ stroke: "#cfd8dc", strokeWidth: ".02rem" }}
          />
          <text x="18" y="25" className="circle-subtitle">
            {intl.formatMessage({ id: "app.shared.session" })}
          </text>

          <text x="18" y="28" className="circle-subvalue">
            {this.getHours(total)}:{this.getMinutes(total)}
            {config.showSeconds && <>:{this.getSeconds(total)}</>}
          </text>
        </svg>
      </Container>
    );
  }
}

export default injectIntl(SessionTimer);
