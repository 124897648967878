const EventEmitter = require("events");

const eventEmitter = new EventEmitter();

window.addEventListener("from_app", onEventFromApp);

function onEventFromApp(event) {
  if (event && event.detail) {
    var detail = JSON.parse(event.detail);
    //alert(event.detail);
    eventEmitter.emit(detail.type, detail.data);
  }
}

function sendToApp(event, data) {
  try {
    // eslint-disable-next-line jsx-control-statements/jsx-jcs-no-undef
    NativeChannel.postMessage(
      JSON.stringify({
        type: event,
        data: data,
      })
    );
  } catch (e) {
    // Not defined
  }
}

eventEmitter.on("to_app", (event, data) => {
  sendToApp(event, data);
});

/* Keyboard events */
let keyboardText = "";
let timerKeyboardText = null;
// Listen for events from the keyboard
document.addEventListener("keydown", (event) => {
  if (event.key === "Enter") {
    if (keyboardText.length > 0) {
      eventEmitter.emit("barcode", keyboardText);
      eventEmitter.emit("nfc_tag", keyboardText);
    }
    keyboardText = "";
  } else if (event.key.length === 1) {
    keyboardText += event.key;
  }
  if (timerKeyboardText) {
    clearTimeout(timerKeyboardText);
  }
  timerKeyboardText = setTimeout(() => {
    keyboardText = "";
  }, 500);
});

module.exports = {
  on: eventEmitter.on.bind(eventEmitter),
  off: eventEmitter.off.bind(eventEmitter),
  send: sendToApp,
};

/*
To test events from console:

(function () {
  const event = new CustomEvent("from_app", {
    "detail": {
      "type": "tag",
      "data": {
        "identifier": "test",
      }
    }
  });
  window.dispatchEvent(event);
})();
*/
