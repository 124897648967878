import { createIntlCache, createIntl } from "react-intl";

import { language, messages } from "../config/localization";

const globalIntlCache = createIntlCache();

const intl = createIntl({ locale: language, messages }, globalIntlCache);

/**
 * Provides support for intl outside react
 */
export default intl;
